import React from "react";
import SelectInput from "../Input/Select.input";

const CourseDetailsComponent = () => {
  return (
    <div className="w-full mt-8">
      <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        Course Details :-
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <SelectInput
          Title="Session"
          name="session"
          Value={["2024-2025", "2025-2026", "2026-2027"]}
        />
        <SelectInput
          Title="Batch Type"
          name="batch"
          Value={["Online Classes", "Offline Classes"]}
        />
        <SelectInput
          Title="Course"
          name="session"
          Value={[
            "GS Exclusive",
            "GS Premium + CSAT",
            "GS Main",
            "GS Foundation",
            "GS Foundation + CSAT",
            "GS Focus",
            "Optional",
            "Crash Course Prelims",
          ]}
        />
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <SelectInput
          Title="Exam"
          name="exam"
          Value={["CSE 2024", "CSE 2025", "CSE 2026"]}
        />
        <SelectInput
          Title="Batch"
          name="time"
          Value={[
            "10:00Am to 12:00Pm",
            "02:00Pm to 04:00Pm",
            "06:00pm to 08:00Pm",
          ]}
        />
        <div className="flex w-1/3 flex-col items-start gap-2"></div>
      </div>
    </div>
  );
};

export default CourseDetailsComponent;
