import React, { FC } from "react";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

interface CardProps {
  title?: string;
  CssClass?: string;
  routes?: string;
}

const HomeCard: FC<CardProps> = ({ title, CssClass, routes }) => {
  return (
    <Link
      to={routes ? routes : "#"}
      className={CssClass + ` flex items- w-full my-3 md:mt-2`}
    >
      <GoDotFill className="text-red-500 text-xl md:text-base" />
      <p>{title ? title : "Admission Open (2025-2026)"}</p>
    </Link>
  );
};

export default HomeCard;
