import React from "react";
import { DummyTeacher } from "../../utils/images";

interface TeacherCardProp {
  NameOfPerson: string;
  Subject: string;
  Exp?: string;
  Image: string;
}

const TeacherCard: React.FC<TeacherCardProp> = ({
  Image,
  NameOfPerson,
  Subject,
  Exp,
}) => {
  return (
    <div className="bg-[#f9f9f9] p-5 w-[20rem] h-[25rem] flex flex-col gap-3 items-center justify-center rounded-lg shadow-md border-[1px]">
      <img
        src={Image ? Image : DummyTeacher}
        alt=""
        className="h-[12.5rem] w-[12.5rem] rounded-lg"
      />
      <h2 className="text-xl font-bold">
        {NameOfPerson ? NameOfPerson : "Dheeraj Singh"}
      </h2>
      <p className="text-sm font-semibold">{Subject ? Subject : "History"}</p>
      {Exp && <p className="text-sm font-semibold">{Exp}</p>}
    </div>
  );
};

export default TeacherCard;
