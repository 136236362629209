import { useState, useEffect } from "react";

interface CountdownProps {
  targetDate: Date; // Date and time to count down to
}

const Timer: React.FC<CountdownProps> = ({ targetDate }) => {
  const calculateTimeLeft = (): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="flex flex-row items-center space-x-2 justify-around bg-[#1328af] w-fit sm:w-[30rem] p-3 md:p-6 rounded-3xl shadow-xl m-4">
      <div className="flex flex-col">
        <span className="text-2xl md:text-4xl font-thin text-white">
          {days !== 0 && days}
        </span>
        <span className="text-sm md:text-md text-white uppercase">
          {days !== 0 ? "days" : ""}
        </span>
      </div>
      <div className="text-2xl md:text-4xl font-thin text-white">
        {days !== 0 ? ":" : ""}
      </div>
      <div className="flex flex-col">
        <span className="text-2xl md:text-4xl font-thin text-white">{hours}</span>
        <span className="text-sm md:text-md text-white uppercase">hours</span>
      </div>
      <div className="text-2xl md:text-4xl font-thin text-white">:</div>
      <div className="flex flex-col">
        <span className="text-2xl md:text-4xl font-thin text-white">{minutes}</span>
        <span className="text-sm md:text-md text-white uppercase">minutes</span>
      </div>
      <div className="text-2xl md:text-4xl font-thin text-white">:</div>
      <div className="flex flex-col">
        <span className="text-2xl md:text-4xl font-thin text-white">{seconds}</span>
        <span className="text-sm md:text-md text-white uppercase">seconds</span>
      </div>
    </div>
  );
};

export default Timer;