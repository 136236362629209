import React, { FC } from "react";
import { layer1 } from "../../utils/images";

interface cardData {
  src?: string;
  title?: string;
  description?: string;
}

const FacultiesCard: FC<cardData> = ({ src, title, description }) => {
  return (
    <div className="bg-[#e0e6f3] flex items-center justify-center p-4 rounded-2xl border-solid border-4">
      <img
        src={src ? src : layer1}
        alt="layer"
        className="h-[120px] w-[120px] rounded-full shadow-xl"
      />
      <div className="ml-4">
        <span className=" capitalize text-xl md:text-2xl font-bold">
          {title ? title : "Exprinced faculties"}
        </span>
        <p className="w-[12rem] md:w-[20rem] text-sm text-black/75 font-medium leading-4">
          {description
            ? description
            : "our support team wil be happy to assist you!"}
        </p>
      </div>
    </div>
  );
};

export default FacultiesCard;
