import React from "react";

interface Props {
  Title: string;
  Value: string[];
  name: string;
}

const RadioInput: React.FC<Props> = ({ Title, Value, name }) => {
  return (
    <div className="flex w-1/3 flex-col items-start gap-2">
      <p className="text-lg font-semibold">
        {Title}
        <span className="text-red-600">*</span> :-
      </p>
      <div className="w-full flex gap-6">
        {Value.map((item, index) => (
          <div className="flex items-center gap-1">
            <input
              key={index}
              autoComplete="off"
              type="radio"
              id={name}
              name={name}
              value={item}
              className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md"
            />
            <label htmlFor={item}>{item}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
