import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";

const DetailsPartComponent = () => {
  return (
    <div className=" md:w-[50%] lg:w-[40%] flex flex-col items-start justify-start h-[25rem] md:h-[35rem]">
      <div>
        <p className="text-3xl font-bold w-[14rem]">Get In Touch With Us!</p>
      </div>
      <div className="mt-8">
        <div className="flex gap-2 my-4">
          <IoCall className="text-[#015af2] h-[2rem] w-[2rem]" />
          <div className="flex flex-col">
            <p className="font-bold text-xl">Office Phone Number</p>
            <p className="text-sm text-[#464b53] leading-4">
              +91 9289298047, 9289298044
            </p>
          </div>
        </div>
        <div className="flex gap-2 my-4">
          <IoMdMail className="text-[#015af2] h-[2rem] w-[2rem]" />
          <div className="flex flex-col">
            <p className="font-bold text-xl">Office Email</p>
            <p className="text-sm text-[#464b53] leading-4">info@agais.in</p>
          </div>
        </div>
        <div className="flex gap-2 my-4">
          <FaLocationDot className="text-[#015af2] h-[2rem] w-[2rem]" />
          <div className="flex flex-col">
            <p className="font-bold text-xl">Address</p>
            <p className="text-sm text-[#464b53] leading-4 w-[10rem]">
              Office No-503, 504, 5 floor, Tradex tower 1, Alpha 1, Commercial
              Belt, Gr. Noida 201308
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPartComponent;
