import React, { ChangeEvent } from "react";

interface Props {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder: string;
}

const ContactFormInput: React.FC<Props> = ({
  name,
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className="flex flex-col w-full mt-4">
      <input
        autoComplete="off"
        type="text"
        id={name}
        name={name}
        value={value}
        className="px-2 py-1 outline-none bg-[#fbfbfb] shadow-md rounded-md"
        placeholder={placeholder}
        onChange={onChange}
        required
      />
    </div>
  );
};

export default ContactFormInput;
