import React, { FC, useState } from "react";
import { IoMdDownload } from "react-icons/io";
import { Link } from "react-router-dom";
import { pdf1, pdf2 } from "../../utils/images";

interface DuelProps {
  paper: string;
  year?: string;
  answer?: string;
  pdfRoute?: string;
  quePaperLink?: string;
  ansPaperLink?: string;
}

const DuelCard: FC<DuelProps> = ({
  paper,
  year,
  answer,
  quePaperLink,
  ansPaperLink,
}) => {
  let full_paper = paper.length;

  let full_title = paper;

  const [hover, setHover] = useState(false);

  const ishover = () => {
    if (full_paper > 10) {
      setHover(() => !hover);
    }
  };

  (function trim() {
    let lenght = paper.length;
    if (lenght > 10) {
      let a = paper.split(" ");
      paper = a[0] + " " + a[1] + "...";

      return paper;
    }
  })();

  return (
    <>
      <div className="relative flex gap-2 lg:gap-4 m-2">
        <button
          className="relative flex rounded-xl p-2 md:gap-6 items-center bg-blue-300 w-[10rem] md:w-[15rem]"
          onMouseEnter={ishover}
          onMouseLeave={() => setHover(false)}
        >
          <div className="rounded-full w-12 h-12 flex items-center justify-center">
            <img
              src={pdf1}
              alt="pdf"
              className="h-8 md:h-10"
            />
          </div>
          <div className="flex flex-col items-cenetr justify-center bg-transparent p-2">
            <span className="text-xs md:text-sm lg:text-base text-black/65 font-bold text-center leading-4">
              {paper ? paper : `Gs paper`}
            </span>
            <span className="text-xs md:text-sm lg:text-base text-black/65 font-bold text-center">
              {year ? year : `2023`}
            </span>
          </div>
          <Link to={quePaperLink ? quePaperLink : ""} target="_blank">
            <IoMdDownload className="text-sm md:text-base lg:text-xl hover:text-white" />
          </Link>
        </button>
        <button className="flex rounded-xl p-2 md:gap-6 items-center bg-blue-300 w-[10rem] md:w-[15rem]">
          <div className="rounded-full w-12 h-12 flex items-center justify-center">
            <img
              src={pdf2}
              alt="pdf"
              className="h-8 md:h-10"
            />
          </div>
          <div className=" w-fit flex flex-col bg-transparent p-2">
            <span className="text-xs md:text-sm lg:text-base text-black/65 font-bold text-center leading-4">
              {answer ? answer : "Answer key"}
            </span>
            <span className="text-xs md:text-sm lg:text-base text-black/65 font-bold text-center">
              {year ? year : "2023"}
            </span>
          </div>
          <Link to={ansPaperLink ? ansPaperLink : ""} target="blank">
            <IoMdDownload className="text-sm md:text-base lg:text-xl hover:text-white" />
          </Link>
        </button>
        {hover && (
          <div className="absolute z-10 right-3 w-[17rem] h-fit rounded-2xl bg-neutral-950/50 p-2 md:p-4">
            <p className="text-sm text-white w-full">{full_title}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default DuelCard;
