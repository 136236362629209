import React, { FC } from "react";
import { ceo1, ceo2 } from "../../utils/images";

interface ceoProps {
  name?: string;
  name2?: string;
  post?: string;
  post2?: string;
  desc?: string;
  desc2?: string;
}

const OurfounderAbout: FC<ceoProps> = ({
  name,
  post,
  desc,
  name2,
  post2,
  desc2,
}) => {
  return (
    <>
      <div className="hidden md:flex flex-col items-center justify-center w-full my-8 gap-8">
        <span className="font-bold text-2xl my-8">
          Our Founder & Co-founder
        </span>
        <div className={" flex relative w-full h-fit items-center"}>
          <div
            className={" w-1/2 flex flex-col bg-red-300 p-4 justify-center "}
          >
            <div className="w-fit h-fit ml-[3rem] lg:ml-[5rem] flex items-center justify-center rounded-full mb-3">
              <img src={ceo1} alt="ceos" className="w-[8rem] h-[8rem]" />
            </div>
            <div className="flex flex-col ml-[3rem] lg:ml-[5rem] bg-white border-2 border-solid w-fit h-fit p-1 text-black font-semibold">
              <span className="text-sm text-center leading-3">
                {name ? name : "amit gupta"}
              </span>
              <span className="text-sm text-center">
                {post ? post : "founder ceo of agias"}
              </span>
            </div>
          </div>
          <div
            style={{ clipPath: "polygon(0 0, 100% 0%, 100% 100%, 10% 100%)" }}
            className={`w-3/4 flex items-center justify-center absolute h-[15rem] right-0 bg-blue-300 p-10`}
          >
            <span className="text-sm w-[40rem]">
              {desc
                ? desc
                : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque, velit nulla architecto consequuntur id, sed aut soluta, maxime hic dignissimos cumque? Magni aliquid est illum eius, ad alias. Rerum ipsa nisi est sequi adipisci, perferendis quod neque, excepturi saepe modi dolorum temporibus aperiam praesentium animi eius veritatis obcaecati harum in iusto repudiandae numquam impedit. Sapiente dolorem consequuntur quaerat ab quae minus quis hic maiores architecto laboriosam asperiores, necessitatibus dolore velit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta vitae magni quibusdam dignissimos. Aut eligendi modi facilis? Saepe dolore itaque quos blanditiis error illo, optio veniam deleniti ab recusandae ad."}
            </span>
          </div>
        </div>
        <div className="flex relative w-full h-fit items-center">
          <div
            style={{ clipPath: "polygon(0 0, 100% 0, 91% 100%, 0% 100%)" }}
            className={`w-3/4 flex items-center justify-center h-[15rem] bg-blue-300 p-10 z-[10]`}
          >
            <span className="text-sm w-[40rem]">
              {desc2
                ? desc2
                : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque, velit nulla architecto consequuntur id, sed aut soluta, maxime hic dignissimos cumque? Magni aliquid est illum eius, ad alias. Rerum ipsa nisi est sequi adipisci, perferendis quod neque, excepturi saepe modi dolorum temporibus aperiam praesentium animi eius veritatis obcaecati harum in iusto repudiandae numquam impedit. Sapiente dolorem consequuntur quaerat ab quae minus quis hic maiores architecto laboriosam asperiores, necessitatibus dolore velit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta vitae magni quibusdam dignissimos. Aut eligendi modi facilis? Saepe dolore itaque quos blanditiis error illo, optio veniam deleniti ab recusandae ad."}
            </span>
          </div>
          <div className="w-1/2 flex flex-col absolute z-[5] bg-red-300 p-4 justify-center items-end right-0">
            <div className="w-fit h-fit mr-[3rem] lg:mr-[5rem] flex items-center justify-center rounded-full mb-3">
              <img src={ceo2} alt="ceos" className="w-[8rem] h-[8rem]" />
            </div>
            <div className="flex flex-col mr-[3rem] bg-white lg:mr-[5rem] border-2 border-solid w-fit h-fit p-1 font-semibold text-black">
              <span className="text-sm text-center leading-3">
                {name2 ? name2 : "amit gupta"}
              </span>
              <span className="text-sm text-center">
                {post2 ? post2 : "founder ceo of agias"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden flex-col items-center justify-center my-8 gap-4">
        <span className="font-bold text-2xl my-4">Our Founder</span>
        <div className="w-full flex flex-col items-center justify-center border-y-2 border-solid shadow-xl py-2">
          <div className="flex items-center w-full justify-center">
            <img src={ceo1} alt="" className="w-[150px] h-[150px]" />
            <div className="flex flex-col ml-[2rem] bg-black border-2 border-solid w-fit h-fit p-1 text-white capitalize">
              <span className="text-lg text-center leading-3">
                {name ? name : "amit gupta"}
              </span>
              <span className="text-lg text-center">
                {post ? post : "founder ceo of agias"}
              </span>
            </div>
          </div>
          <div className="w-full flex items-center justify-center h-fit p-10">
            <span className="text-sm w-[40rem]">
              {desc
                ? desc
                : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque, velit nulla architecto consequuntur id, sed aut soluta, maxime hic dignissimos cumque? Magni aliquid est illum eius, ad alias. ipsa nisi est sequi adipisci, perferendis quod neque, excepturi saepe modi dolorum temporibus aperiam praesentium animi eius veritatis obcaecati harum in iusto repudiandae numquam impedit. Sapiente dolorem consequuntur quaerat ab quae minus quis hic maiores architecto laboriosam asperiores, necessitatibus dolore velit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta vitae magni quibusdam dignissimos. Aut eligendi modi facilis? Saepe dolore itaque quos blanditiis error illo, optio veniam deleniti ab recusandae ad."}
            </span>
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center border-y-2 border-solid shadow-xl py-2">
          <div className="flex flex-row-reverse items-center w-full justify-center">
            <img src={ceo2} alt="" className="w-[150px] h-[150px]" />
            <div className="flex flex-col mr-[2rem] bg-black border-2 border-solid w-fit h-fit p-1 text-white capitalize">
              <span className="text-lg text-center leading-3">
                {name2 ? name2 : "amit gupta"}
              </span>
              <span className="text-lg text-center">
                {post2 ? post2 : "founder ceo of agias"}
              </span>
            </div>
          </div>
          <div className="w-full flex items-center justify-center h-fit p-10">
            <span className="text-sm w-[40rem]">
              {desc2
                ? desc2
                : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque, velit nulla architecto consequuntur id, sed aut soluta, maxime hic dignissimos cumque? Magni aliquid est illum eius, ad alias. Rerum ipsa nisi est sequi adipisci, perferendis quod neque, excepturi saepe modi dolorum temporibus aperiam praesentium animi eius veritatis obcaecati harum in iusto repudiandae numquam impedit. Sapiente dolorem consequuntur quaerat ab quae minus quis hic maiores architecto laboriosam asperiores, necessitatibus dolore velit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta vitae magni quibusdam dignissimos. Aut eligendi modi facilis? Saepe dolore itaque quos blanditiis error illo, optio veniam deleniti ab recusandae ad."}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurfounderAbout;
