import React from "react";
import { bannerImages } from "../../dummy data/dummy.data";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "swiper/css/a11y";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";

const CarouselComponent = () => {
  return (
    <>
      <Swiper
        loop={true}
        autoplay={{
          delay: 7000,
        }}
        cssMode={true}
        scrollbar={{ draggable: true }}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        modules={[
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        className="mySwiper"
      >
        {bannerImages.map((el, index) => (
          <SwiperSlide key={index}>
            <Link to={el.link}>
              <img
                src={el.src_Pc}
                alt={`banner-${index}`}
                className="min-w-full hidden lg:block"
              />
              <img
                src={el.src_mob}
                alt={`banner-${index}`}
                className="min-w-full block lg:hidden"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CarouselComponent;
