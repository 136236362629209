import React, { FC } from 'react';
import { FaRupeeSign, FaCheck } from "react-icons/fa";
import NormalButton from '../Buttons/normal.button';

interface PriceProps {
    types?: string;
    price?: string;
    mode?: string;
    access?: string;
    completion?: string;
    support?: string;
    profit?: string;
    id?: string;
}

const PriceCard: FC<PriceProps> = ({types, price, mode, access, completion, support, profit, id}) => {
    return (
        <div className='flex w-[18rem] flex-col items-center justify-center shadow-lg rounded-lg p-6'>
            <div className='flex flex-col items-center justify-center my-4'>
                <span className='font-semibold text-lg'>{types?types:`CSAT`}</span>
                <span className='capitalize text-xs text-gray-300'>course</span>
                <br />
                <span className='flex items-center justify-center text-5xl font-bold'><FaRupeeSign className='text-base mr-[-5px]' />{price?price:`15000`}</span>
                <span className='text-sm text-gray-500 font-semibold'>/Student</span>
            </div>
            <hr className='w-full' />
            <div>
                <span className='flex text-sm font-semibold items-center justify-start mt-4 gap-1'><FaCheck className='text-green-400' />Mode: {mode? mode:'Offline.'}</span>
                <span className='flex text-sm font-semibold items-center justify-start mt-4 gap-1'><FaCheck className='text-green-400' />{access? access:'2 year from the beginning of the course'}</span>
                <span className='flex text-sm font-semibold items-center justify-start mt-4 gap-1'><FaCheck className='text-green-400' />{completion? completion:'course completion in around 3 months'}</span>
                <span className='flex text-sm font-semibold items-center justify-start mt-4 gap-1'><FaCheck className='text-green-400' />{support?support:'Team of best faculties.'}</span>
                <span className='flex text-sm font-semibold items-center justify-start mt-4 gap-1'><FaCheck className='text-green-400' />{profit?profit:'Free study materials, libary and more'}</span>
            </div>
            <div className='flex items-center justify-center mt-8'>
                <NormalButton route={`/courses/${id}`} classCss='rounded-full px-12 text-white font-bold bg-blue-600 py-1'>Buy Now</NormalButton>
            </div>
        </div>
    );
}

export default PriceCard;
