import React from "react";
import CarouselComponent from "../components/homeComponents/carousel.component";
import AdmissionComponent from "../components/homeComponents/Admission.component";
import { date } from "../dummy data/dummy.data";
import NewComponent from "../components/homeComponents/newUpdates.component";
import DisplayCoursesComponent from "../components/homeComponents/displayCourses.component";
import HaveanyqueryComponent from "../components/haveanyquery.component";
import InformationComponent from "../components/homeComponents/Achivements.component";
import SpecialComponent from "../components/homeComponents/WhatMakesUsSpecial.component";
import FaqComponent from "../components/homeComponents/faq.component";
// import AppAdvertisementCompnent from '../components/homeComponents/appAdvertisement.compnent';
import WhatsetusapartComponents from "../components/homeComponents/whatsetusapart.components";
import AdvertisingComponent from "../components/OurAdversting.componet";
// import Navbar2Component from '../components/homeComponents/navbar2.component';

const HomePage = () => {
  return (
    <div>
      <CarouselComponent />
      <InformationComponent />
      {date > new Date() ? <AdmissionComponent targetDate={date} /> : ""}
      <NewComponent />
      {/* <Navbar2Component/> */}
      <DisplayCoursesComponent />
      {/* <AppAdvertisementCompnent/> */}
      <HaveanyqueryComponent />
      <WhatsetusapartComponents />
      <SpecialComponent />
      <AdvertisingComponent marginTop="mt-6" />
      <FaqComponent />
    </div>
  );
};

export default HomePage;
