import React from "react";
import { white } from "../utils/icons";
import { Link } from "react-router-dom";
import ResgistrationDetailsComponent from "../components/ResgistrationPageComponent/ResgistrationDetails.component";
import CourseDetailsComponent from "../components/ResgistrationPageComponent/CourseDetails.component";
import EducationDetailsComponent from "../components/ResgistrationPageComponent/EducationDetails.compnent";
import AddressDetailsComponent from "../components/ResgistrationPageComponent/AddressDetails.compnent";
import TermConditionComponent from "../components/ResgistrationPageComponent/TermCondition.component";
import UploadComponent from "../components/ResgistrationPageComponent/Upload.component";

const Registration = () => {
  return (
    <div className="w-full h-full">
      <nav className="bg-primary h-[7rem] md:h-[5rem] py-2 px-4 md:px-8 flex items-center justify-between">
        <Link to={"/"} className="flex items-center">
          <img src={white} alt="Logo" className="h-[4rem] w-[4rem]" />
          <span className="text-[3.2rem] leading-3 text-white font-semibold">
            IAS
          </span>
        </Link>
        <div className="bg-white w-[11rem] md:w-[17rem] flex flex-col items-center rounded-md">
          <p className="font-bold">Contact Us</p>
          <div className="flex flex-col md:flex-row items-center gap-1 md:gap-3">
            <p className="font-semibold">+91-9289298047,</p>
            <p className="font-semibold">+91-9289298044</p>
          </div>
        </div>
      </nav>
      <div className="w-full p-4">
        <ResgistrationDetailsComponent />
        <CourseDetailsComponent />
        <div className="w-full mt-8">
          <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
            Educational Details :-
          </div>
          <EducationDetailsComponent Title="10th :-" />
          <EducationDetailsComponent Title="12th :-" />
          <EducationDetailsComponent Title="Graduation :-" />
          <EducationDetailsComponent Title="Post Graduation :-" />
        </div>
        <AddressDetailsComponent Title="Address Details :-" />
        <AddressDetailsComponent Title="Other Address Details :-" />
        <TermConditionComponent />
        <UploadComponent />
        <div className="w-full mt-12 p-6">
          <div className="flex items-center gap-3">
            <input type="checkbox" id="check" name="check" required />
            <label htmlFor="check">
              I Agree Terms & Conditions / मैं नियम और शर्तों से सहमत हूँ।
            </label>
          </div>
          <div className="flex items-center mt-12 justify-center md:justify-end gap-4">
            <Link
              to={"/courses"}
              className="bg-secondary text-white px-6 py-2 text-xl font-semibold rounded-lg hover:bg-red-400"
            >
              Cancel
            </Link>
            <Link
              to={"/"}
              className="bg-primary text-white px-6 py-2 text-xl font-semibold rounded-lg hover:bg-blue-700"
            >
              Submit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
