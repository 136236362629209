import React, { FC, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

interface accordionProps {
  title?: string;
  desc?: string;
}

const Accordion: FC<accordionProps> = ({ title, desc }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-center justify-center w-full md:w-[80%] mb-2">
      <div
        className="bg-[#e3e8f4] w-full py-[5px] md:py-[10px] px-[15px] md:px-[40px] flex justify-between items-center cursor-pointer"
        onClick={() => setOpen(() => !open)}
      >
        <p className=" text-sm leading-6 md:text-lg font-semibold">
          {title ? title : "Who you are jerk?"}
        </p>
        <div className=" text-[20px] md:text-[30px]">
          {open ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
        </div>
      </div>
      <div
        className={`w-[100%] bg-[#f2f4fa] grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm md:text-base ${
          open
            ? "grid-rows-[1fr] opacity-100 p-4"
            : "grid-rows-[0fr] opacity-0 p-0"
        }`}
      >
        <div className=" overflow-hidden text-center">{desc}</div>
      </div>
    </div>
  );
};

export default Accordion;
