import React from "react";
// import OurCard from "../Cards/our.card";

const WwaAbout = () => {
  return (
    <div className="w-full mb-12 flex flex-col items-center justify-center">
      <p className="text-3xl font-bold my-4"> AG-IAS</p>
      <span className=" capitalize text-2xl font-bold mb-4 text-center lg:text-start">
        Toady's Aspirant, Tomorrow's Achiever
      </span>
      <p className="text-center w-[90%] lg:w-[50rem]">
        Welcome to AG-IAS, your premier partner in navigating the challenging
        world of competitive exams. Founded with a vision to empower aspiring
        IAS officers, our mission is to provide comprehensive, high-quality
        coaching and resources to help you achieve your goals. At AG-IAS, we
        value excellence, dedication, and integrity. Our team of experienced
        educators and industry experts is committed to delivering personalized
        guidance and strategic insights to ensure you’re well-prepared for every
        stage of the examination process.
      </p>
      {/* <div className="flex flex-col md:flex-row items-center justify-center w-full p-8 gap-8">
        <OurCard
          desc="Welcome to AG-IAS, your premier partner in navigating the challenging world of competitive exams. Founded with a vision to empower aspiring IAS officers, our mission is to provide comprehensive, high-quality coaching and resources to help you achieve your goals.

At AG-IAS, we value excellence, dedication, and integrity. Our team of experienced educators and industry experts is committed to delivering personalized guidance and strategic insights to ensure you’re well-prepared for every stage of the examination process.
"
        />
      </div> */}
    </div>
  );
};

export default WwaAbout;
