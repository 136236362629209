import React from "react";

interface Props {
  Title: string;
  name: string;
  Value: string[];
}

const SelectInput: React.FC<Props> = ({ Title, name, Value }) => {
  return (
    <div className="flex w-1/3 flex-col items-start gap-2">
      <label htmlFor={name} className="text-lg font-semibold">
        {Title}
        <span className="text-red-600">*</span> :-
      </label>
      <select
        name={name}
        id={name}
        className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md w-full"
        required
      >
        <option value="">Select</option>
        {Value.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
