import React from 'react';
import Accordion from '../Cards/accordion';
import { faqQA } from '../../dummy data/dummy.data';

const FaqComponent = () => {
    return (
        <div className='flex flex-col items-center justify-center w-full p-2 md:p-6'>
            <span className='font-bold text-2xl mb-3'>FAQ</span>
            {
                faqQA.map((e, index) => (
                    <Accordion
                        key={index}
                        title={e.Q}
                        desc={e.a}
                    />
                ))
            }
        </div>
    );
}

export default FaqComponent;
