import React from "react";
import HomeCard from "../Cards/Home.card";
import LatestCard from "../Cards/latest.card";
import { VideoArray, Whats_New_Data } from "../../dummy data/dummy.data";

const NewComponent = () => {
  return (
    <div className="w-full lg:h-[70vh] bg-green flex flex-col md:flex-row gap-1 mt-4 p-4 md:mt-2 shadow-xl">
      <div className="md:w-[50%] h-full flex flex-col items-center justify-center">
        <span className=" capitalize text-3xl font-bold">what's new</span>
        <div
          className="
          bg-[#e0e6f3]
                w-[90%] 
                h-[20rem] 
                mt-2
                rounded-2xl 
                overflow-auto 
                pt-[0rem]
                px-[1rem]
                mb:pt-6 
                flex 
                flex-col 
                items-center 
                justify-start 
                shadow-xl
                "
          style={{ scrollbarWidth: "none" }}
        >
          {Whats_New_Data.map((item, index) => (
            <HomeCard
              key={index}
              title={item.title}
              routes={item.routes}
              CssClass="text-sm"
            />
          ))}
        </div>
      </div>
      <span className="bg-neutral-900/10 h-full w-1"></span>
      <div className="md:w-[50%] flex flex-col items-center justify-center">
        <span className=" capitalize text-3xl font-bold">
          live & latest videos
        </span>
        <div
          className="
                w-[90%] 
                h-[20rem] 
                mt-2
                mb:mt-0
                rounded-2xl 
                overflow-auto
                px-[1rem]
                mb:pt-6 
                flex
                flex-wrap
                lg:flex
                items-center 
                justify-center 
                shadow-xl
                bg-[#e0e6f3]
                "
          style={{ scrollbarWidth: "none" }}
        >
          {VideoArray.map((e, index) => (
            <LatestCard
              key={index}
              title={e.title}
              src={e.src}
              routes={e.routes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewComponent;
