import React, { FC } from 'react';
import { online } from '../../utils/images';
import { Link } from 'react-router-dom';

interface CardProps {
    src?: string;
    title?: string;
    routes?: string;
}

const LatestCard:FC<CardProps> = ({src, title, routes}) => {
    return (
        <Link to={routes?routes:'#'} target='blank' className='w-full md:w-fit h-fit p-2 flex flex-col items-center justify-center mt-0'>
            <span className='w-[25px] h-[5px] bg-gray-400 rounded-xl mb-2 '></span>
            <img src={src?src:online} alt="card-thumbnail" className='h-full sm:h-[70px] md:h-[70px] w-full md:w-[140px] shadow-lg'/>
            <p className='text-primary font-semibold text-xl md:text-[10px] my-1'>{title?title:'Lorem ipsum dolor sit amet.'}</p>
            <button className='bg-primary text-white flex items-center justify-center p-4 md:p-3 h-[1rem] md:h-[12px] text-sm rounded-full'>Watch Now</button>
        </Link>
    );
}

export default LatestCard;
