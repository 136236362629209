    const IdChecker = (i: any[]) => {

    let Cid: any[] = [];

    i.map((e) => (
        Cid.push(e.id)
    ))

    return Cid;
}


export { IdChecker };