import React from "react";
import { achivement } from "../../dummy data/dummy.data";

const SpecialComponent = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center p-6 my-4">
      <p className="font-bold capitalize text-2xl">what's make us special</p>
      <div className="w-full flex justify-center flex-wrap gap-6 mt-6">
        {achivement.map((e, index) => (
          <div
            key={index}
            className={
              " flex flex-col items-center justify-center w-[18rem] h-fit"
            }
          >
            <div
              className="h-[120px] w-[120px] bg-blue-200 rounded-full bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${e.src})` }}
            ></div>
            <span className="text-lg font-bold capitalize">
              {e.name ? e.name : "Greena Rathi"}
            </span>
            <p className="text-center text-sm text-[#747474]">
              {e.des
                ? e.des
                : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae asperiores consequuntur dolorem quae amet."}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialComponent;
