import React from "react";
import FormInput from "../Input/Form.input";

interface EducationDetailsCompnentProp {
  Title: string;
}

const EducationDetailsComponent: React.FC<EducationDetailsCompnentProp> = ({
  Title,
}) => {
  return (
    <div className="w-full my-8">
      <div className="w-[50%] bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        {Title}
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <FormInput Title="Board/University" name="B/U" type="text" />
        <FormInput Title="Year" name="year" type="text" />
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <FormInput Title="% Marks" name="marks" type="text" />
        <FormInput Title="Subject" name="Subject" type="text" />
      </div>
    </div>
  );
};

export default EducationDetailsComponent;
