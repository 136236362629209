import React, { FC } from 'react';

interface BannerPrps {
    title?: string;
}

const BannerComponent:FC<BannerPrps> = ({title}) => {
    return (
        <div className={'flex flex-col justify-center w-full h-40 md:h-60 bg-blue-800 mb-8'}>
            {<div className='flex relative w-2/3 md:w-3/5 lg:w-2/5 md:h-2/5 py-4 md:py-8 bg-red-600 left-[-1rem] items-center justify-center capitalize skew-x-[-15deg]'>
                <p className=' text-white font-bold text-2xl md:text-[3rem]'>{title?title:'about us '}</p>
                <span className=' absolute right-[-1.5rem] bg-white w-1/4 h-1/3 ml-8 skew-x-[-15deg]'></span>
            </div>
            }
        </div>
    );
}

export default BannerComponent;
