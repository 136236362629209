import React, { useState } from "react";
import CourseCard from "../Cards/course.card";
import { CourseDetail } from "../../dummy data/dummy.data";

const DisplayCoursesComponent = () => {
  const [value, setValue] = useState("offline");

  const data = CourseDetail.filter((user) => user.mode === value).slice(0, 3);

  return (
    <div className="w-full bg-[#e0e6f3] py-4 flex flex-col items-center justify-center shadow-xl my-8">
      <div className="bg-primary text-white rounded-full px-[3rem] flex gap-[2rem] py-2 shadow-xl">
        <button
          className={
            value === "offline"
              ? "font-bold text-lg px-4 rounded-full text-black bg-white"
              : " font-bold text-lg hover:text-black hover:bg-white px-4 rounded-full"
          }
          value="upse"
          onClick={() => setValue("offline")}
        >
          Offline
        </button>
        <button
          className={
            value === "online"
              ? "font-bold text-lg px-4 rounded-full text-black bg-white"
              : " font-bold text-lg hover:text-black hover:bg-white px-4 rounded-full"
          }
          value="upse"
          onClick={() => setValue("online")}
        >
          Online
        </button>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-8 mt-8">
        {data.map((e, index) => (
          <CourseCard
            key={index}
            title={e.title}
            fee={e.flow[1].content}
            route={`/courses/${e.id}`}
            duration={e.flow[2].content}
            timing={e.flow[3].content}
            teacher={e.teacher}
            hours={e.studyHours}
            src={e.src}
            ButtonName="More About"
          />
        ))}
      </div>
    </div>
  );
};

export default DisplayCoursesComponent;
