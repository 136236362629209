import React from "react";
import TeacherCard from "../Cards/Teacher.card";

interface OurFaculityTeamProp {
  subTitle: string;
  title: string;
  desc?: string;
  FaculitiesArr: any[];
}

const OurFaculityTeam: React.FC<OurFaculityTeamProp> = ({
  subTitle,
  title,
  desc,
  FaculitiesArr,
}) => {
  return (
    <div className="w-full h-fit flex flex-col items-center justify-center my-16">
      <h2 className="text-[#015AF2] text-lg font-bold">{subTitle}</h2>
      <h2 className="text-3xl md:text-4xl font-bold my-1 text-center">
        {title}
      </h2>
      {desc && <p className="font-semibold w-[60rem] text-center">{desc}</p>}
      <div className="flex flex-wrap items-start gap-5 p-8 justify-center">
        {FaculitiesArr.map((details, index) => (
          <TeacherCard
            key={index}
            NameOfPerson={details.name}
            Image={details.ImageSrc}
            Subject={details.subject}
            Exp={details.Experience}
          />
        ))}
      </div>
    </div>
  );
};

export default OurFaculityTeam;
