import React from "react";
import FormInput from "../Input/Form.input";
import SelectInput from "../Input/Select.input";
import RadioInput from "../Input/Radio.input";

const ResgistrationDetailsComponent = () => {
  return (
    <div className="w-full">
      <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        Registration Details :-
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <FormInput
          Title="Candidate Name"
          name="Name"
          type="text"
          placeHolder="Full Name"
        />
        <FormInput Title="Date of Birth" name="date" type="date" />
        <SelectInput
          Title="Medium"
          name="medium"
          Value={["Hindi", "English"]}
        />
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <RadioInput
          Title="Gender"
          Value={["Male", "Female", "Other"]}
          name="gender"
        />
        <SelectInput
          Title="Categories"
          name="categories"
          Value={["General", "OBC", "SC", "ST"]}
        />
        <RadioInput
          Title="Previous Attempt"
          Value={["Yes", "No"]}
          name="attempt"
        />
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <FormInput
          Title="Contact no"
          type="text"
          name="Contact"
          placeHolder="+919787******"
        />
        <FormInput
          Title="Email id"
          type="email"
          name="Email"
          placeHolder="Example@gmail.com"
        />
        <FormInput
          Title="Confirm Email id"
          type="email"
          name="Confirm_Email"
          placeHolder="Example@gmail.com"
        />
      </div>
    </div>
  );
};

export default ResgistrationDetailsComponent;
