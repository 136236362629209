import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavRoutes } from '../dummy data/dummy.data';
import { AGIASLOGO } from '../utils/icons';
import { RxCross2 } from "react-icons/rx";
import PopUpComponent from './popup.component';

interface sliderprop {
    classCSS: string;
    handleClick: any;
}

const SidebarComponent: FC<sliderprop> = ({ classCSS, handleClick }) => {

    const [focusedNavTitle, setFocusedNavTitle] = useState<string>('');
    const[auth, setAuth] = useState(false);

    const Auth = ()=>{
        setAuth(()=>!auth)
    }

    const showSubNav = (str: string) => {
        if (focusedNavTitle === str) {
            const filterNav = NavRoutes.filter(el => el.subNav.length > 0 && el.navTitle === str);
            if (filterNav.length) {
                setFocusedNavTitle(() => '');
                filterNav[0].isHover = false;
            }
        } else {
            const previousFilteredNav = NavRoutes.filter(el => el.subNav.length > 0 && el.navTitle === focusedNavTitle);
            if (previousFilteredNav.length) {
                previousFilteredNav[0].isHover = false;
            }
            const filterNav = NavRoutes.filter(el => el.subNav.length > 0 && el.navTitle === str);
            if (filterNav.length) {
                setFocusedNavTitle(() => str);
                filterNav[0].isHover = true;
            }

        }
    }

    return (
        <>
            <div className={classCSS + ' w-60 fixed lg:hidden h-full bg-white px-4 py-2 shadow-lg top-0 left-0 z-50 overflow-y-auto'}>
                <div className='my-2 mb-4 relative'>
                    <div className='flex items-center'>
                        <img src={AGIASLOGO} alt="agias logo" width={150} />
                    </div>
                    <RxCross2 className='top-1 right-1 absolute' onClick={handleClick} />
                </div>
                <hr />
                <ul className='mt-3 font-bold'>
                    {
                        NavRoutes.map((nav, index) => (
                            <li key={index} className='p-2' onClick={nav.subNav.length ? ()=>{}:()=>handleClick()}>
                                <NavLink to={nav.subNav.length > 0 ? '#' : nav.link}
                                    className={({ isActive }) =>
                                        isActive && !nav.subNav.length
                                            ? "border-b-2 pb-2 border-[#dd000e] text-[#dd000e]  font-medium text-lg capitalize"
                                            : "hover:border-b-2 hover:pb-2 hover:border-[#dd000e] hover:text-[#dd000e] text-gray-500 font-medium text-lg capitalize"
                                    }
                                    onClick={() => showSubNav(nav.navTitle)}
                                >{nav.navTitle}
                                </NavLink>
                                <>
                                    {
                                        (nav.isHover) && nav.subNav.map((sub, index) => (
                                            <div key={index} className={`w-[100%] shadow-md mt-2 grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm md:text-base ${nav.isHover ? 'grid-rows-[1fr] opacity-100 p-4' : 'grid-rows-[0fr] opacity-0 p-0'
                                                }`} >
                                                <NavLink onClick={handleClick} to={sub.link} className={({ isActive }) =>
                                                    isActive
                                                        ? "border-b-2 pb-2 border-[#dd000e] text-[#dd000e]  font-medium text-lg capitalize"
                                                        : "hover:border-b-2 hover:pb-2 hover:border-[#dd000e] hover:text-[#dd000e] text-gray-500 font-medium text-lg capitalize"
                                                }>{sub.navTitle}</NavLink>
                                            </div>
                                        ))
                                    }
                                </>
                            </li>
                        ))
                    }
                </ul>
                <button onClick={()=>{ Auth(); handleClick() }} className='bg-primary text-white flex font-normal p-2 rounded-xl mt-4 items-center justify-center animate-pulse capitalize'>Free Demo class</button>
            </div>
            {auth && <PopUpComponent handleClick={Auth} />}
        </>
    );
}

export default SidebarComponent;
