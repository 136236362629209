import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../layouts/root.layout";
import HomePage from "../pages/Home.page";
import AboutPage from "../pages/About.page";
import CoursePage from "../pages/Course.page";
import PaperPage from "../pages/PrelimsPaper.page";
import MainsPaperPage from "../pages/MainsPaper.page";
import NcertBookPage from "../pages/NcertBook.page";
// import CurrenAffairsPage from "../pages/CurrenAffairs.page";
// import TestSeriesPage from "../pages/TestSeries.page";
import ContactPage from "../pages/Contact.page";
import CourseDetailsPage from "../pages/CourseDetails.page";
import NotFound from "../pages/NotFound";
import Registration from "../pages/Resgistration.page";
import PrivacyPolicyPage from "../pages/PrivacyPolicy.page";
// import ContentPage from "../pages/Content.page";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/about-us",
    element: <RootLayout />,
    children: [
      {
        path: "",
        element: <AboutPage />,
      },
    ],
  },
  {
    path: "/courses",
    element: <RootLayout />,
    children: [
      {
        path: "",
        element: <CoursePage />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "prelims-previous-year-papers",
        element: <PaperPage />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "main-previous-year-papers",
        element: <MainsPaperPage />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "ncert-books",
        element: <NcertBookPage />,
      },
    ],
  },
  // {
  //     path: '/',
  //     element: <RootLayout />,
  //     children: [{
  //         path: 'test-series',
  //         element: <TestSeriesPage />
  //     }]
  // },
  // {
  //     path: '/',
  //     element: <RootLayout />,
  //     children: [{
  //         path: 'current-affairs',
  //         element: <CurrenAffairsPage />
  //     }]
  // },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "contact-us",
        element: <ContactPage />,
      },
    ],
  },
  // {
  //     path: '/',
  //     element: <RootLayout />,
  //     children: [{
  //         path: 'current-affairs/:id',
  //         element: <ContentPage />
  //     }]
  // },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "courses/:id",
        element: <CourseDetailsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
    ],
  },
  {
    path: "/registration",
    element: <Registration />,
  },
]);

export default Router;
