import React, { ChangeEvent, FormEvent, useState } from "react";
import ContactFormInput from "./ContactForm.Input";

interface Istate {
  username: string;
  email: string;
  mobileNumber: string;
  message: string;
  agree: string;
  location: string;
}

const FormComponent = () => {
  const [contactFormData, setContactFormData] = useState<Istate>({
    username: "",
    email: "",
    mobileNumber: "",
    location: "",
    message: "",
    agree: "",
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setContactFormData({ ...contactFormData, [name]: value });
  };

  const onFormSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("form data : ", contactFormData);
    setContactFormData({
      username: "",
      email: "",
      mobileNumber: "",
      location: "",
      message: "",
      agree: "",
    });
  };

  return (
    <div className="md:w-[50%] lg:w-[60%] h-[35rem]">
      <span className="text-2xl font-bold">Have any Question?</span>
      <form className="mt-4 w-full" onSubmit={onFormSubmitHandler}>
        <ContactFormInput
          name="username"
          value={contactFormData.username}
          onChange={onChangeInputField}
          placeholder="Name"
        />
        <ContactFormInput
          name="email"
          value={contactFormData.email}
          onChange={onChangeInputField}
          placeholder="Email"
        />
        <ContactFormInput
          name="mobileNumber"
          value={contactFormData.mobileNumber}
          onChange={onChangeInputField}
          placeholder="Phone Number"
        />
        <ContactFormInput
          name="location"
          value={contactFormData.location}
          onChange={onChangeInputField}
          placeholder="Enter you address"
        />

        <div className="w-full mt-6">
          <textarea
            id="textarea"
            placeholder="Query/Message"
            name="message"
            value={contactFormData.message}
            className="w-full h-[10rem] !important px-2 py-1 outline-none bg-[#fbfbfb] shadow-md rounded-md"
            onChange={onChangeInputField}
          ></textarea>
        </div>
        <div className="w-full flex items-center mt-6 gap-2">
          <input
            type="checkbox"
            id="boolean"
            name="agree"
            className="px-2 py-1 outline-none border-solid border-2 rounded-md"
            placeholder="Phone No"
            onChange={onChangeInputField}
            required
          />
          <label htmlFor="boolean" className="text-base font-medium">
            I agree to the term and condition
          </label>
        </div>
        <div className="mt-6 flex items-center justify-end gap-6">
          <button
            type="submit"
            className="bg-[#525bb2] px-6 rounded-lg py-1 broder-2 border-[#525bb2] text-white font-medium"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
