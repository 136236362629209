import React from "react";
import BannerComponent from "../components/Banner/banner.component";
import FormComponent from "../components/ContactusComponent/Form.component";
import NewLetterComponent from "../components/ContactusComponent/NewLetter.Component";
import DetailsPartComponent from "../components/ContactusComponent/DetailsPart.Component";

const ContactPage: React.FC = () => {
  return (
    <>
      <BannerComponent title={"Contact us"} />
      <div className="w-full md:px-[5rem] lg:px-[10rem]">
        <div className="w-full flex flex-col md:flex-row items-center justify-center my-8 px-[1rem] lg:px-[4rem]">
          <DetailsPartComponent />
          <FormComponent />
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.2946171904!2d77.50844252428664!3d28.47067279141471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cea7ee9000001%3A0xbc6810110c3ad990!2sTradex%20tower%201!5e0!3m2!1sen!2sin!4v1720272942140!5m2!1sen!2sin"
          className="w-full my-8"
          title="Location"
        />
        <NewLetterComponent />
      </div>
    </>
  );
};

export default ContactPage;
