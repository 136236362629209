import React from "react";

const TermConditionComponent = () => {
  return (
    <div className="w-full mt-8">
      <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        Term and Conditions of AGIAS :-
      </div>
      <ol className="mt-4 p-4 list-decimal">
        <li className="text-black font-semibold text-lg">
          To take admission in any course (you can change course or leave any
          subject) within one week no changes will be made after that.
        </li>
        <li className="text-black font-semibold text-lg">
          Fee will be accepted in specific in the same manner as directly by the
          institute
        </li>
        <li className="text-black font-semibold text-lg">
          After taking enrollment in any course. if you want to cancel the
          amission within one week, the fee will be refunded to the students's
          bank account after a deduction of 20 percent
        </li>
      </ol>
    </div>
  );
};

export default TermConditionComponent;
