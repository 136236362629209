import React from "react";
import FacultiesCard from "../Cards/faculties.card";
import { wsuaData } from "../../dummy data/dummy.data";

const WhatsetusapartComponents = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center mt-[2rem]">
      <span className=" capitalize font-bold text-2xl">What sets us apart</span>
      <p className="my-4 text-center w-[60%]">
        We offer a blend of in-depth study materials, interactive classes, and
        one-on-one mentoring to address your unique needs and learning style.
        Our proven methodologies are designed to build your confidence and
        enhance your skills, giving you the edge you need to succeed.
      </p>
      <div className="flex flex-wrap items-center justify-center mt-8 gap-6">
        {wsuaData.map((e, index) => (
          <FacultiesCard
            key={index}
            title={e.title}
            description={e.description}
            src={e.src}
          />
        ))}
      </div>
    </div>
  );
};

export default WhatsetusapartComponents;
