import React from "react";
import Banner2Component from "../components/Banner/banner2.component";
import { prelims } from "../utils/images";
// import PdfButton from "../components/Buttons/pdf.button";
import { ncertBooks } from "../dummy data/dummy.data";
import { Link } from "react-router-dom";

const NcertBookPage = () => {
  return (
    <div className="w-full">
      <Banner2Component classCSS="h-[10rem] md:h-[15rem]" url={prelims} />
      <div className="w-full flex flex-col items-center justify-center py-4 px-2">
        {/* <div className="bg-blue-100 px-2 lg:px-8 py-4 rounded-lg flex flex-col items-center">
          <div className="w-full flex flex-wrap items-center justify-center">
            {ncertBooks.map((e, index) => (
              <PdfButton
                route={e.id}
                key={index}
                paper={e.title}
                year={e.totalNo}
                classCSS={index % 2 === 0 ? "bg-red-400" : "bg-blue-400"}
              />
            ))}
          </div>
        </div> */}
        <div className="w-full p-8 flex flex-col">
          {/* <>
                        <span className='text-2xl font-bold my-3'>Art & Culture</span>
                        <div className='w-full border-2 px-3 py-1 border-solid'>
                            <div className=' capitalize font-semibold flex justify-between'><span>TextBook For Class-XI(An Introduction to Indian Art - Part-1)</span><span className='bg-blue-300 px-3 rounded-full text-white font-bold'>download</span></div>
                        </div>
                        <div className='w-full border-2 px-3 py-1 border-solid'>
                            <div className=' capitalize font-semibold flex justify-between'><span>TextBook For Class-XI(An Introduction to Indian Art - Part-1)</span><span className='bg-blue-300 px-3 rounded-full text-white font-bold'>download</span></div>
                        </div>
                        <div className='w-full border-2 px-3 py-1 border-solid'>
                            <div className=' capitalize font-semibold flex justify-between'><span>TextBook For Class-XI(An Introduction to Indian Art - Part-1)</span><span className='bg-blue-300 px-3 rounded-full text-white font-bold'>download</span></div>
                        </div>
                    </> */}
          {ncertBooks.map((e, index) => (
            <div key={index} className="my-3" id={e.id}>
              <div className="mb-2">
                <span className="text-2xl font-bold">
                  {e.title ? e.title : "Art & Culture"}
                </span>
              </div>
              {e.PDF.map((e, index) => (
                <div
                  key={index}
                  className="w-full border-2 px-3 py-1 border-solid"
                >
                  <div className=" capitalize font-semibold flex justify-between">
                    <span className="text-xs md:text-base">
                      {e.title
                        ? e.title
                        : `TextBook For Class-XI(An Introduction to Indian Art - Part-1)`}
                    </span>
                    <Link
                      to={e.download}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-blue-300 flex justify-center items-center text-xs md:text-base px-2 md:px-3 rounded-full text-white font-semibold md:font-bold"
                    >
                      download
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NcertBookPage;
