import React from "react";
import HaveanyqueryComponent from "../components/haveanyquery.component";
// import InformationComponent from "../components/homeComponents/information.component";
import BannerComponent from "../components/Banner/banner.component";
import WwaAbout from "../components/aboutComponents/WhoWeAre.about";
import OurfounderAbout from "../components/aboutComponents/ourfounder.about";
import OurFaculityTeam from "../components/aboutComponents/OurFaculityTeam.about";
import { OurTeacher, ourTeam } from "../dummy data/dummy.data";
import WhatsetusapartComponents from "../components/homeComponents/whatsetusapart.components";

const AboutPage = () => {
  return (
    <div>
      <BannerComponent />
      <WwaAbout />
      <OurfounderAbout
        name="Mr. Amit Goel"
        name2="Mrs. Veena Khanna"
        post="Founder of AG IAS"
        post2="Co-Founder of AG IAS"
        desc={
          "Mr. Amit Goel, from Delhi, India, is a dynamic figure with over 30 years of experience in the travel industry. He is the successful founder of “Dream Travel & Tours Inc,” operating in India, the U.S.A, and Canada, providing exceptional travel experiences. Beyond travel, he is involved in medicine with MEDONGO, revolutionizing healthcare access. He is also ventured into the culinary domain with “Mughlai Junction” restaurants, known for Mughlai cuisine. In education, he founded AG IAS in 2016, guiding aspiring individuals in achieving their goals. Through his diverse endeavors, Mr. Goel continues to make a positive impact on society, epitomizing the spirit of making a difference."
        }
        desc2={
          "Veena Khanna, an esteemed educationist, writer, and co-founder of AG IAS, has dedicated 25 years to shaping young minds, specializing in Mathematics and English. Her acclaimed book, “Keen Knowledge of English Grammar,” serves as a trusted resource for grades 1 to 5. Beyond teaching, she promotes education through her work with the magazine “Window to the Future” by “Hamari Pahchan” NGO. As co-founder of AG IAS, Veena’s leadership has made it a thriving institution, empowering students for exams like U.P.S.C and PCS. Her journey continues to inspire and mentor countless students towards success, making her a beacon of educational excellence."
        }
      />
      <WhatsetusapartComponents />
      {/* <div className='w-full text-center capitalize text-2xl font-bold'>our faculty</div> */}
      {/* <InformationComponent /> */}
      <OurFaculityTeam
        subTitle={"Our Faculties"}
        title={"Meet our passionate team of educators"}
        FaculitiesArr={OurTeacher}
      />
      <OurFaculityTeam
        subTitle={"Our Team"}
        title={"Our Professional Team"}
        FaculitiesArr={ourTeam}
      />
      <div className="w-full flex items-center justify-center my-12">
        <p className=" md:w-[50%] text-lg text-center font-bold">
          Ready to embark on your journey to success?
          <br />
          Join AG-IAS today and take the first step towards achieving your IAS
          dreams!
        </p>
      </div>
      <HaveanyqueryComponent />
    </div>
  );
};

export default AboutPage;
