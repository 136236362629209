import React, { useState } from "react";
import { Mail } from "../../utils/images";

const NewLetterComponent = () => {
  const [email, setEmail] = useState("");

  const handleClick = () => {
    setEmail("");
  };

  return (
    <div className="w-full flex flex-col md:flex-row items-center justify-between gap-2 md:gap-[4rem] bg-[#015af2] my-8 md:px-6 lg:px-10 py-2 rounded-lg">
      <div className="flex items-center gap-3">
        <img
          src={Mail}
          alt="mail"
          className="hidden lg:block h-[4rem] w-[4rem] rounded-lg"
        />
      </div>
      <div className="md:w-[10rem] lg:w-[12rem] flex flex-col items-center text-white">
        <p className="w-[11rem] lg:w-fit text-sm lg:text-base font-bold">
          Subscribe our news letter
        </p>
        {/* <p className='text-xs lg:text-sm'>Title Here</p> */}
      </div>
      <div className="flex bg-[white] rounded-md pl-2">
        <input
          type="email"
          className="outline-none rounded-l-md"
          placeholder="Email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <button
          className="bg-[#e11911] flex items-center rounded-md text-white justify-center p-2"
          onClick={handleClick}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default NewLetterComponent;
