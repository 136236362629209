import {
  AmitSrivastava,
  AnikKumar,
  AvinashGautam,
  ban1,
  ban2,
  CA,
  clock,
  duration,
  ban1_mob,
  Durgesh,
  layer1,
  layer2,
  layer3,
  layer4,
  money,
  name1,
  name2,
  name3,
  Offline,
  online,
  Sheetal,
  thumb1,
  thumb2,
  thumb3,
  thumb4,
  thumb5,
  timing,
  ban2_mob,
} from "../utils/images";
import { FaGraduationCap } from "react-icons/fa";
import { TbFileSettings } from "react-icons/tb";
import { RiUserAddLine } from "react-icons/ri";
import { PiStudentFill, PiProjectorScreenChartLight } from "react-icons/pi";
import { PiClockCountdownFill } from "react-icons/pi";
import { GiReceiveMoney, GiDuration, GiRingingAlarm } from "react-icons/gi";
import { LiaSearchDollarSolid } from "react-icons/lia";

export const AlertData = [
  {
    description: "admisson open for latest batch of csat 2025",
    link: "/registration",
  },
  {
    description:
      "| registration open for prelims practice test 2024, Weekend batch starting on September 8, 2024.",
    link: "/registration",
  },
];

export const NavRoutes = [
  {
    navTitle: "Home",
    link: "/",
    isHover: false,
    subNav: [],
  },
  {
    navTitle: "courses",
    link: "/courses",
    isHover: false,
    subNav: [],
  },
  // {
  //   navTitle: "test series",
  //   link: "/test-series",
  //   isHover: false,
  //   subNav: [],
  // },
  {
    navTitle: "resources",
    link: "/resources",
    isHover: false,
    subNav: [
      {
        navTitle: "prelims previous year papers",
        link: "/prelims-previous-year-papers",
        subNav: [],
      },
      {
        navTitle: "main previous year papers",
        link: "/main-previous-year-papers",
        subNav: [],
      },
      {
        navTitle: "NCERT Books",
        link: "/ncert-books",
        subNav: [],
      },
      // {
      //   navTitle: "current affairs",
      //   link: "/current-affairs",
      //   isHover: false,
      //   subNav: [
      //     {
      //       navTitle: "Daily",
      //       link: "/daily",
      //       subNav: [],
      //     },
      //     {
      //       navTitle: "weekly",
      //       link: "/weekly",
      //       subNav: [],
      //     },
      //     {
      //       navTitle: "monthly",
      //       link: "/monthly",
      //       subNav: [],
      //     },
      //     {
      //       navTitle: "mains hacker",
      //       link: "/mains-hacker",
      //       subNav: [],
      //     },
      //     {
      //       navTitle: "Prelims cracker",
      //       link: "/prelims-cracker",
      //       subNav: [],
      //     },
      //   ],
      // },
    ],
  },
  {
    navTitle: "about us",
    link: "/about-us",
    isHover: false,
    subNav: [],
  },
  {
    navTitle: "contact us",
    link: "/contact-us",
    isHover: false,
    subNav: [],
  },
  // {
  //   navTitle: "blog",
  //   link: "/blog",
  //   isHover: false,
  //   subNav: [],
  // },
  // {
  //   navTitle: "store",
  //   link: "/store",
  //   isHover: false,
  //   subNav: [],
  // },
];

export const Nav2Data = [
  {
    navTitle: "courses",
    icon: FaGraduationCap,
    link: "/courses",
    subNav: [
      {
        navTitle: "offline courses",
        link: "/offline-courses",
        subNav: [],
      },
      {
        navTitle: "online courses",
        link: "/online-courses",
        subNav: [],
      },
      {
        navTitle: "Postal courses",
        link: "/postal-courses",
        subNav: [],
      },
    ],
  },
  {
    navTitle: "resources",
    icon: TbFileSettings,
    link: "/resources",
    subNav: [
      {
        navTitle: "previous year papers",
        link: "/previous-year-papers",
        subNav: [],
      },
      {
        navTitle: "down to earth",
        link: "/down-to-earth",
        subNav: [],
      },
      {
        navTitle: "yojana",
        link: "/yojana",
        subNav: [],
      },
      {
        navTitle: "Kurukshetra",
        link: "/kurukshetra",
        subNav: [],
      },
      {
        navTitle: "Economic Survey",
        link: "/economic-survey",
        subNav: [],
      },
      {
        navTitle: "NCERTs",
        link: "/ncert",
        subNav: [],
      },
      {
        navTitle: "AgIAS",
        link: "/agias-study-materials",
        subNav: [],
      },
      {
        navTitle: "Union Budget",
        link: "/union-budget",
        subNav: [],
      },
      {
        navTitle: "beyond classroom",
        link: "/beyond-classroom",
        subNav: [],
      },
      {
        navTitle: "videos",
        link: "/videos",
        subNav: [],
      },
    ],
  },
  // {
  //   navTitle: "test series",
  //   icon: HiClipboardList,
  //   link: "/test-series",
  //   subNav: [
  //     {
  //       navTitle: "prelims previous year papers",
  //       link: "/prelims-previous-year-papers",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "main previous year papers",
  //       link: "/main-previous-year-papers",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "NCERT Books",
  //       link: "/ncert-books",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "editorials",
  //       link: "/editorials",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "Toppers Talk",
  //       link: "/toppers-talk",
  //       subNav: [],
  //     },
  //   ],
  // },
  // {
  //   navTitle: "current affairs",
  //   icon: HiMiniSpeakerWave,
  //   link: "/current-affairs",
  //   subNav: [
  //     {
  //       navTitle: "Daily",
  //       link: "/daily",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "weekly",
  //       link: "/weekly",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "monthly",
  //       link: "/monthly",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "mains hacker",
  //       link: "/mains-hacker",
  //       subNav: [],
  //     },
  //     {
  //       navTitle: "Prelims cracker",
  //       link: "/prelims-cracker",
  //       subNav: [],
  //     },
  //   ],
  // },
  // {
  //   navTitle: "blog",
  //   icon: BiSolidMessageSquareDetail,
  //   link: "/blog",
  //   subNav: [],
  // },
  // {
  //   navTitle: "store",
  //   icon: IoMdCart,
  //   link: "/store",
  //   subNav: [],
  // },
];

export const SearchData = ["hello", "world", "api", "search", "website"];

export const bannerImages = [
  {
    src_Pc: ban1,
    src_mob: ban1_mob,
    link: "/",
  },
  {
    src_Pc: ban2,
    src_mob: ban2_mob,
    link: "/",
  },
];

export const date = new Date("2024-07-21");

export const wsuaData = [
  {
    title: "Experienced faculites",
    src: layer1,
    description:
      "Our instructors are seasoned UPSC veterans with years of teaching experience, providing insights and strategies that go beyond textbooks.",
  },
  {
    title: "Our promises",
    src: layer2,
    description:
      "We stand by not just till the end of the program you are enrolled to, but till you succeed",
  },
  {
    title: "Batch size",
    src: layer3,
    description:
      "Limited batch size for individual attention; Precise direction on what to study, how to study & when to study",
  },
  {
    title: "Mentorship and Guidance",
    src: layer4,
    description:
      "Each student is assigned a mentor for ongoing support, providing advice on preparation strategies and emotional well-being throughout the journey",
  },
];

export const information = [
  {
    title: "total aspirant",
    icon: RiUserAddLine,
    no: "5k",
    color: "bg-red-600",
  },
  {
    title: "students",
    icon: PiStudentFill,
    no: "750+",
    color: "bg-yellow-600",
  },
  {
    title: "exp. faculty",
    icon: FaGraduationCap,
    no: "30+",
    color: "bg-green-600",
  },
  {
    title: "UPSC Course",
    icon: PiProjectorScreenChartLight,
    no: "20+",
    color: "bg-pink-600",
  },
];

export const achivement = [
  {
    src: name1,
    name: "Nisha Gupta",
    des: "Joining Agias was the best decision. The focus on both academics and personality development truly set me up for success.",
    rank: "Air 2",
  },
  {
    src: name2,
    name: "Rahul Mehta",
    des: "The comprehensive study material covered everything I needed for the exam. I felt well-prepared and confident on test day.",
    rank: "Air 1",
  },
  {
    src: name3,
    name: "Karan Sharma",
    des: "I felt well-prepared for each exam stage, and the guidance I received helped build my confidence throughout this journey.",
    rank: "Air 3",
  },
];

export const faqQA = [
  {
    no: "1",
    isHover: false,
    Q: "Are IAS online classes available?",
    a: "Yes, we offer both online and offline classes to accommodate different learning preferences.",
  },
  {
    no: "2",
    isHover: false,
    Q: "What subjects are covered in the IAS syllabus?",
    a: "Our program covers all subjects in the UPSC syllabus, including Prelims, Mains, and Interview preparation.",
  },
  {
    no: "3",
    isHover: false,
    Q: "Do you provide study materials for UPSC?",
    a: "Yes, we provide comprehensive study materials, including notes, practice papers, and current affairs resources.",
  },
  {
    no: "4",
    isHover: false,
    Q: "What is the process for enrolling in a AG-IAS course?",
    a: "You can enroll by visiting our website, filling out the registration form, or contacting our admissions team for assistance.",
  },
  {
    no: "5",
    isHover: false,
    Q: "Can I join mid-session from AG-IAS classes?",
    a: "Depending on the course availability, students may join mid-session, but it is recommended to start from the beginning for optimal learning.",
  },
];

export const categories = [
  {
    routes: "/",
    title: "Motivation",
  },
  {
    routes: "/",
    title: "Math",
  },
  {
    routes: "/",
    title: "Science",
  },
  {
    routes: "/",
    title: "English",
  },
  {
    routes: "/",
    title: "SST",
  },
  {
    routes: "/",
    title: "General Knowlegde",
  },
  {
    routes: "/",
    title: "Exam",
  },
  {
    routes: "/",
    title: "perpare",
  },
];

export const newzLatest = [
  {
    no: "1",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
  {
    no: "2",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
  {
    no: "3",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
  {
    no: "4",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
  {
    no: "5",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
  {
    no: "6",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
];

export const newzPopular = [
  {
    no: "1",
    title: "Noida's IAS Vanguard - The AGIAS Academy's Commitment to success",
  },
  {
    no: "2",
    title: "Noida's IAS Vanguard - The AGIAS Academy's Commitment to success",
  },
  {
    no: "3",
    title: "Noida's IAS Vanguard - The AGIAS Academy's Commitment to success",
  },
  {
    no: "4",
    title: "Noida's IAS Vanguard - The AGIAS Academy's Commitment to success",
  },
  {
    no: "5",
    title: "Noida's IAS Vanguard - The AGIAS Academy's Commitment to success",
  },
  {
    no: "6",
    title: "Tip from AG IAS Acadmey for UPSC CSE 2025 Perparation",
  },
];

export const courses = [
  {
    courseName: "UPSC Course Fee Structure",
    offline: [
      {
        course: "c1",
        name: "CSAT",
        price: "15000",
        Mode: "Offline",
        access: "2 years from the beginning of the course",
        completion: "course completion in around 3 months",
        support: "Team of best faculties.",
        profit: "Free study materials, library, and smart classes.",
      },
      {
        course: "c2",
        name: "GS (PRE + MIANS)",
        price: "70000",
        Mode: "Offline",
        access: "2 year from the beginning of the course",
        completion: "course completion in around 15 months",
        support: "Team of best faculties.",
        profit: "Free study materials, library, and smart classes.",
      },
      {
        course: "c3",
        name: "GS(PRE + MAINS + CSAT)",
        price: "90000",
        Mode: "Offline",
        access: "validity:- 2 year from Course Start",
        completion: "course completion in around 15 months",
        support: "Team of best faculties.",
        profit: "Free study materials, library, and smart classes.",
      },
    ],
    online: [
      {
        course: "c4",
        name: "CSAT",
        price: "11000",
        Mode: "Online",
        access: "2 year from the beginning of the course",
        completion: "course completion in around 3 months",
        support: "Team of best faculties.",
        profit: "•	Free study materials, library, and smart classes.",
      },
      {
        course: "c5",
        name: "GS (PRE + MIANS)",
        price: "25000",
        Mode: "Online",
        access: "2 year from the beginning of the course",
        completion: "course completion in around 15 months",
        support: "Team of best faculties.",
        profit: "•	Free study materials, library, and smart classes.",
      },
      {
        course: "c6",
        name: "GS(PRE + MAINS + CSAT)",
        price: "35000",
        Mode: "Online",
        access: "validity:- 2 year from Course Start",
        completion: "course completion in around 15 months",
        support: "Team of best faculties.",
        profit: "•	Free study materials, library, and smart classes.",
      },
    ],
  },
  {
    courseName: "State-PCS Course Fee",
    offline: [
      {
        course: "c7",
        name: "Full Course",
        price: "1,60,000",
        Mode: "Offline",
        access: "validity:- 2 year from Course Start",
        completion: "course completion in around 18 months",
        support: "Team of best faculties.",
        profit: "•	Free study materials, library, and smart classes.",
      },
    ],
    // online: [
    //   {
    //     course: "c1",
    //     name: "CSAT",
    //     price: "11000",
    //     Mode: "Online",
    //     access: "2 year from the beginning of the course",
    //     completion: "course completion in around 3 months",
    //     support: "Team of best faculties.",
    //     profit: "Free study materials, libary and more",
    //   },
    //   {
    //     course: "c1",
    //     name: "Foundation",
    //     price: "21000",
    //     Mode: "Online",
    //     access: "2 year from the beginning of the course",
    //     completion: "course completion in around 15 months",
    //     support: "Team of best faculties.",
    //     profit: "Free study materials, libary and more",
    //   },
    //   {
    //     course: "c1",
    //     name: "Full Course",
    //     price: "30000",
    //     Mode: "Online",
    //     access: "validity:- 2 year from Course Start",
    //     completion: "course completion in around 15 months",
    //     support: "Team of best faculties.",
    //     profit: "Free study materials, libary and more",
    //   },
    // ],
  },
];

export const Paper = [
  {
    title: "prelims paper 1 (pervious years paper)",
    paper: [
      {
        Paper: "Gs Paper",
        Year: "2024",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2024.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "Gs Paper",
        Year: "2023",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2023.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "Gs Paper",
        Year: "2022",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2022.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "Gs Paper",
        Year: "2021",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2021.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper - 1 (2021).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2020",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2020.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/Genereal Studies Paper -1 SetA (2020).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2019",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2019.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper-1 (2019).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2018",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2018.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper - 1 (2018).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2017",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2017.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper - 1 (2017).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2016",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2016.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper - 1 (2016).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2015",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2015.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper - 1 (2015).pdf",
      },
      {
        Paper: "Gs Paper",
        Year: "2014",
        quePaperLink: "/pdf/GS Paper 1/GENERAL STUDIES PAPER I 2014.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS1 key/General Studies Paper-1 (2014).pdf",
      },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2013",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2012",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2011",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2010",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2009",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2008",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2007",
      //   Answer: "Answer Key",
      // },
      // {
      //   Paper: "Gs Paper",
      //   Year: "2006",
      //   Answer: "Answer Key",
      // },
    ],
  },
  {
    title: "prelims paper 2 (pervious years paper)",
    paper: [
      {
        Paper: "CSAT Paper",
        Year: "2024",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2024.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "CSAT Paper",
        Year: "2023",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2023.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "CSAT Paper",
        Year: "2022",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2022.pdf",
        Answer: "Answer Key",
        ansPaperLink: "",
      },
      {
        Paper: "CSAT Paper",
        Year: "2021",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2021.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper -2 (2021).pdf",
      },
      {
        Paper: "CSAT Paper hsfehjghdjgjhsgsjdgjksdilusilris",
        Year: "2020",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2020.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper - 2 (2020).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2019",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2019.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper - 2 (2019).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2018",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2018.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper-2 (2018).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2017",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2017.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper - 2 (2017).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2016",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2016.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper-2 (2016).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2015",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2015.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper-2 (2015).pdf",
      },
      {
        Paper: "CSAT Paper",
        Year: "2014",
        quePaperLink: "/pdf/GS Paper 2/GENERAL STUDIES PAPER II 2014.pdf",
        Answer: "Answer Key",
        ansPaperLink: "/pdf/GS2 key/General Studies Paper - 2 (2014).pdf",
      },
    ],
  },
];

export const MainsPaper = [
  // {
  //   title: "UPSC Mains Pervious Paper 2024",
  //   paper: [
  //     {
  //       title: "GS 1",
  //       year: "2024",
  //     },
  //     {
  //       title: "GS 2",
  //       year: "2024",
  //     },
  //     {
  //       title: "GS 3",
  //       year: "2024",
  //     },
  //     {
  //       title: "GS 4",
  //       year: "2024",
  //     },
  //     {
  //       title: "Eassay Writting",
  //       year: "2024",
  //     },
  //   ],
  // },
  {
    title: "UPSC Mains Pervious Paper 2023",
    paper: [
      {
        title: "GS 1",
        year: "2023",
        quePaperLink: "/pdf/2023/Mains GS Paper 1 2023.pdf",
      },
      {
        title: "GS 2",
        year: "2023",
        quePaperLink: "/pdf/2023/Mains GS Paper 2 2023.pdf",
      },
      {
        title: "GS 3",
        year: "2023",
        quePaperLink: "/pdf/2023/Mains GS Paper 3 2023.pdf",
      },
      {
        title: "GS 4",
        year: "2023",
        quePaperLink: "/pdf/2023/Mains GS Paper 4 2023.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2023",
        quePaperLink: "/pdf/2023/Mains Essay 2023.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2022",
    paper: [
      {
        title: "GS 1",
        year: "2022",
        quePaperLink: "/pdf/2022/Mains GS Paper 1 2022.pdf",
      },
      {
        title: "GS 2",
        year: "2022",
        quePaperLink: "/pdf/2022/Mains GS Paper 2 2022.pdf",
      },
      {
        title: "GS 3",
        year: "2022",
        quePaperLink: "/pdf/2022/Mains GS Paper 3 2022.pdf",
      },
      {
        title: "GS 4",
        year: "2022",
        quePaperLink: "/pdf/2022/Mains GS Paper 4 2022.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2022",
        quePaperLink: "/pdf/2022/Mains essay 2022.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2021",
    paper: [
      {
        title: "GS 1",
        year: "2021",
        quePaperLink: "/pdf/2021/Mains GS Paper 1 2021.pdf",
      },
      {
        title: "GS 2",
        year: "2021",
        quePaperLink: "/pdf/2021/Mains GS Paper 2 2021.pdf",
      },
      {
        title: "GS 3",
        year: "2021",
        quePaperLink: "/pdf/2021/Mains GS Paper 3 2021.pdf",
      },
      {
        title: "GS 4",
        year: "2021",
        quePaperLink: "/pdf/2021/Mains GS Paper 4 2021.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2021",
        quePaperLink: "/pdf/2021/Mains Essay 2021.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2020",
    paper: [
      {
        title: "GS 1",
        year: "2020",
        quePaperLink: "/pdf/2020/Mains GS Paper 1 2020.pdf",
      },
      {
        title: "GS 2",
        year: "2020",
        quePaperLink: "/pdf/2020/Mains GS Paper 2 2020.pdf",
      },
      {
        title: "GS 3",
        year: "2020",
        quePaperLink: "/pdf/2020/Mains GS Paper 3 2020.pdf",
      },
      {
        title: "GS 4",
        year: "2020",
        quePaperLink: "/pdf/2020/Mains GS Paper 4 2020.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2020",
        quePaperLink: "/pdf/2020/Mains essay 2020.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2019",
    paper: [
      {
        title: "GS 1",
        year: "2019",
        quePaperLink: "/pdf/2019/Mains GS Paper 1 2019.pdf",
      },
      {
        title: "GS 2",
        year: "2019",
        quePaperLink: "/pdf/2019/Mains GS Paper 2 2019.pdf",
      },
      {
        title: "GS 3",
        year: "2019",
        quePaperLink: "/pdf/2019/Mains GS Paper 3 2019.pdf",
      },
      {
        title: "GS 4",
        year: "2019",
        quePaperLink: "/pdf/2019/Mains GS Paper 4 2019.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2019",
        quePaperLink: "/pdf/2019/Mains Essay 2019.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2018",
    paper: [
      {
        title: "GS 1",
        year: "2018",
        quePaperLink: "/pdf/2018/Mains GS Paper 1 2018.pdf",
      },
      {
        title: "GS 2",
        year: "2018",
        quePaperLink: "/pdf/2018/Mains GS Paper 2 2018.pdf",
      },
      {
        title: "GS 3",
        year: "2018",
        quePaperLink: "/pdf/2018/Mains GS Paper 3 2018.pdf",
      },
      {
        title: "GS 4",
        year: "2018",
        quePaperLink: "/pdf/2018/Mains GS Paper 4 2018.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2018",
        quePaperLink: "/pdf/2018/Mains Essay 2018.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2017",
    paper: [
      {
        title: "GS 1",
        year: "2017",
        quePaperLink: "/pdf/2017/Mains GS Paper 1 2017.pdf",
      },
      {
        title: "GS 2",
        year: "2017",
        quePaperLink: "/pdf/2017/Mains GS Paper 2 2017.pdf",
      },
      {
        title: "GS 3",
        year: "2017",
        quePaperLink: "/pdf/2017/Mains GS Paper 3 2017.pdf",
      },
      {
        title: "GS 4",
        year: "2017",
        quePaperLink: "/pdf/2017/Mains GS Paper 4 2017.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2017",
        quePaperLink: "/pdf/2017/Mains Essay 2017.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2016",
    paper: [
      {
        title: "GS 1",
        year: "2016",
        quePaperLink: "/pdf/2016/Mains GS Paper 1 2016.pdf",
      },
      {
        title: "GS 2",
        year: "2016",
        quePaperLink: "/pdf/2016/Mains GS Paper 2 2016.pdf",
      },
      {
        title: "GS 3",
        year: "2016",
        quePaperLink: "/pdf/2016/Mains GS Paper 3 2016.pdf",
      },
      {
        title: "GS 4",
        year: "2016",
        quePaperLink: "/pdf/2016/Mains GS Paper 4 2016.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2016",
        quePaperLink: "/pdf/2016/Mains Essay 2016.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2015",
    paper: [
      {
        title: "GS 1",
        year: "2015",
        quePaperLink: "/pdf/2015/Mains GS Paper 1 2015.pdf",
      },
      {
        title: "GS 2",
        year: "2015",
        quePaperLink: "/pdf/2015/Mains GS Paper 2 2015.pdf",
      },
      {
        title: "GS 3",
        year: "2015",
        quePaperLink: "/pdf/2015/Mains GS Paper 3 2015.pdf",
      },
      {
        title: "GS 4",
        year: "2015",
        quePaperLink: "/pdf/2015/Mains GS Paper 4 2015.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2015",
        quePaperLink: "/pdf/2015/Mains Essay 2015.pdf",
      },
    ],
  },
  {
    title: "UPSC Mains Pervious Paper 2014",
    paper: [
      {
        title: "GS 1",
        year: "2014",
        quePaperLink: "/pdf/2014/Mains GS Paper 1 2014.pdf",
      },
      {
        title: "GS 2",
        year: "2014",
        quePaperLink: "/pdf/2014/Mains GS Paper 2 2014.pdf",
      },
      {
        title: "GS 3",
        year: "2014",
        quePaperLink: "/pdf/2014/Mains GS Paper 3 2014.pdf",
      },
      {
        title: "GS 4",
        year: "2014",
        quePaperLink: "/pdf/2014/Mains GS Paper 4 2014.pdf",
      },
      {
        title: "Eassay Writting",
        year: "2014",
        quePaperLink: "/pdf/2014/Mains Essay 2014.pdf",
      },
    ],
  },
];

export const ncertBooks = [
  {
    title: "Economics",
    totalNo: "Total PDF 5",
    id: "eco",
    PDF: [
      {
        title: "Textbook for Class-IX (Economics)",
        download: "/pdf/Economics/Textbook for Class-IX (Economics).pdf",
      },
      {
        title: "Textbook for Class-X (Understanding Economic Development)",
        download:
          "/pdf/Economics/Textbook for Class-X (Understanding Economic Development).pdf",
      },
      {
        title: "Textbook for Class-XI (Indian Economic Development)",
        download:
          "/pdf/Economics/Textbook for Class-XI (Indian Economic Development).pdf",
      },
      {
        title: "Textbook for Class-XII (Introductory Microeconomics – Part-1)",
        download:
          "/pdf/Economics/Textbook for Class-XII (Introductory Microeconomics - Part-1).pdf",
      },
      {
        title: "Textbook for Class-XII (Introductory Macroeconomics – Part-2)",
        download:
          "/pdf/Economics/Textbook for Class-XII (Introductory Macroeconomics - Part-2).pdf",
      },
    ],
  },
  {
    title: "Science",
    totalNo: "Total PDF 7",
    id: "sci",
    PDF: [
      {
        title: "Textbook for Class-VI (Science)",
        download: "/pdf/Science/Textbook for Class-VI (Science).pdf",
      },
      {
        title: "Textbook for Class-VII (Science)",
        download: "/pdf/Science/Textbook for Class-VII (Science).pdf",
      },
      {
        title: "Textbook for Class-VIII (Science)",
        download: "/pdf/Science/Textbook for Class-VIII (Science).pdf",
      },
      {
        title: "Textbook for Class-IX (Science)",
        download: "/pdf/Science/Textbook for Class-IX (Science).pdf",
      },
      {
        title: "Textbook for Class-IX (Science – Exemplar Problems)",
        download:
          "/pdf/Science/Textbook for Class-IX (Science - Exemplar Problems).pdf",
      },
      {
        title: "Textbook for Class-X (Science)",
        download: "/pdf/Science/Textbook for Class-X (Science).pdf",
      },
      {
        title: "Textbook for Class-X (Science – Exemplar Problems)",
        download:
          "/pdf/Science/Textbook for Class-X (Science - Exemplar Problems).pdf",
      },
    ],
  },
  {
    title: "Geography",
    totalNo: "Total PDF 9",
    id: "geo",
    PDF: [
      {
        title: "Textbook for Class-VI (Social Science - The Earth Our Habitat)",
        download:
          "/pdf/Geography/Textbook for Class-VI (Social Science - The Earth Our Habitat).pdf",
      },
      {
        title: "Textbook for Class-VII (Social Science - Our Environment)",
        download:
          "/pdf/Geography/Textbook for Class-VII (Social Science - Our Environment).pdf",
      },
      {
        title:
          "Textbook for Class-VIII (Social Science - Resources and Development)",
        download:
          "/pdf/Geography/Textbook for Class-VIII (Social Science - Resources and Development).pdf",
      },
      {
        title: "Textbook for Class-IX (Social Science - Contemporary India-I)",
        download:
          "/pdf/Geography/Textbook for Class-IX (Social Science - Contemporary India-I).pdf",
      },
      {
        title:
          "Textbook for Class-X (Social Science - Contemporary India Part-II)",
        download:
          "/pdf/Geography/Textbook for Class-X (Social Science - Contemporary India Part-II).pdf",
      },
      {
        title: "Textbook for Class-XI (Fundamentals of Physical Geography)",
        download:
          "/pdf/Geography/Textbook for Class-XI (Fundamentals of Physical Geography).pdf",
      },
      {
        title: "Textbook for Class-XI (India-Physical Environment)",
        download:
          "/public/pdf/Geography/Textbook for Class-XI (India-Physical Environment).pdf",
      },
      {
        title: "Textbook for Class-XI (Practical Work in Geography - Part-I)",
        download:
          "/pdf/Geography/Textbook for Class-XI (Practical Work In Geography - Part-1).pdf",
      },
      {
        title: "Textbook for Class-XII (India People and Economy)",
        download:
          "/pdf/Geography/Textbook for Class-XII (India People and Economy).pdf",
      },
      {
        title: "Textbook for Class-XII (Fundamentals of Human Geography)",
        download:
          "/pdf/Geography/Textbook for Class-XII (Fundamentals of Human Geography).pdf",
      },
    ],
  },
  {
    title: "Art & culture",
    totalNo: "Total PDF 3",
    id: "art",
    PDF: [
      {
        title: "Textbook for Class-XI (An Introduction to Indian Art – Part-1)",
        download:
          "/pdf/Art_and_Culture/Textbook_for_Class_XI_An_Introduction_to_Indian_Art_Part_1.pdf",
      },
      {
        title: "Textbook for Class-XI (Living Craft Traditions of India)",
        download:
          "/pdf/Art_and_Culture/Textbook for Class-XI (Living Craft Traditions of India).pdf",
      },
      {
        title:
          "Textbook for Class-XII (Craft Traditions of India Past, 16 Present and Future)",
        download:
          "/pdf/Art_and_Culture/Textbook for Class-XII (Craft Traditions of India Past, 16 Present and Future).pdf",
      },
    ],
  },
  {
    title: "Biology",
    totalNo: "Total PDF 2",
    id: "bio",
    PDF: [
      {
        title: "Textbook for Class-XI (Biology)",
        download: "/pdf/Biology/Textbook for Class-XI (Biology).pdf",
      },
      {
        title: "Textbook for Class-XII (Biology)",
        download: "/pdf/Biology/Textbook for Class-XII (Biology).pdf",
      },
    ],
  },
  {
    title: "Chemistry",
    totalNo: "Total PDF 4",
    id: "chem",
    PDF: [
      {
        title: "Textbook for Class-XI (Chemistry – Part-I)1",
        download:
          "/pdf/Chemistry/Textbook for Class-XI (Chemistry - Part-I)1.pdf",
      },
      {
        title: "Textbook for Class-XI (Chemistry – Part-I)2",
        download:
          "/pdf/Chemistry/Textbook for Class-XI (Chemistry - Part-I)2.pdf",
      },
      {
        title: "Textbook for Class-XII (Chemistry – Part-I)",
        download:
          "/pdf/Chemistry/Textbook for Class-XII (Chemistry - Part-I).pdf",
      },
      {
        title: "Textbook for Class-XII (Chemistry – Part-II)",
        download:
          "/pdf/Chemistry/Textbook for Class-XII (Chemistry - Part-II).pdf",
      },
    ],
  },
  {
    title: "Sociology",
    totalNo: "Total PDF 4",
    id: "socio",
    PDF: [
      {
        title: "Textbook for Class-XI (Introducing Sociology)",
        download:
          "/pdf/Sociology/Textbook for Class-XI (Introducing Sociology).pdf",
      },
      {
        title: "Textbook for Class-XI (Understanding Society)",
        download:
          "/pdf/Sociology/Textbook for Class-XI (Understanding Society).pdf",
      },
      {
        title: "Textbook for Class-XII (Indian Society)",
        download: "/pdf/Sociology/Textbook for Class-XII (Indian Society).pdf",
      },
      {
        title:
          "Textbook for Class-XII (Social Change and Development In India)",
        download:
          "/pdf/Sociology/Textbook for Class-XII (Social Change and Development In India).pdf",
      },
    ],
  },
  {
    title: "Polity",
    id: "Poli",
    totalNo: "Total PDF 9",
    PDF: [
      {
        title:
          "Textbook for Class-VI (Social Science – Social and Political Life - I)",
        download:
          "/pdf/Polity/Textbook for Class-VI (Social Science - Social And Political Life - I).pdf",
      },
      {
        title: "Textbook for Class-VII (Social and Political Life)",
        download:
          "/pdf/Polity/Textbook for Class-VII (Social and Political Life).pdf",
      },
      {
        title:
          "Textbook for Class-VIII (Social Science – Social and Political Life-III)",
        download:
          "/pdf/Polity/Textbook for Class-VIII (Social Science - Social and Political Life-III).pdf",
      },
      {
        title: "Textbook for Class-IX (Social Science – Democratic Politics-I)",
        download:
          "/pdf/Polity/Textbook for Class-IX (Social Science - Democratic Politics-I).pdf",
      },
      {
        title: "Textbook for Class-X (Social Science – Democratic Politics-II)",
        download:
          "/pdf/Polity/Textbook for Class-X (Social Science - Democratic Politics-II).pdf",
      },
      {
        title: "Textbook for Class-XI (Indian Constitution at Work)",
        download:
          "/pdf/Polity/Textbook for Class-XI (Indian Constitution at Work).pdf",
      },
      {
        title: "Textbook for Class-XI (Political Theory)",
        download: "/pdf/Polity/Textbook for Class-XI (Political Theory).pdf",
      },
      {
        title: "Textbook for Class-XII (Contemporary World Politics)",
        download:
          "/pdf/Polity/Textbook for Class-XII (Contemporary World Politics).pdf",
      },
      {
        title: "Textbook for Class-XII (Politics in India Since Independence)",
        download:
          "/pdf/Polity/Textbook for Class-XII (Politics in India Since Independence).pdf",
      },
    ],
  },
  {
    title: "History",
    totalNo: "Total PDF 9",
    id: "His",
    PDF: [
      {
        title: "Textbook for Class-VI (Social Science - Our Pasts-I)",
        download:
          "/pdf/History/Textbook for Class-VI (Social Science - Our Pasts-I).pdf",
      },
      {
        title:
          "Textbook for Class-VII (Social Science - Social and Political Life-II)",
        download:
          "/pdf/History/Textbook for Class-VII (Social Science - Social and Political Life-II).pdf",
      },
      {
        title:
          "Textbook for Class-VIII (Social Science - Our Pasts-III) Part-2",
        download:
          "/pdf/History/Textbook for Class-VIII (Social Science - Our Pasts-III) Part-2.pdf",
      },
      {
        title:
          "Textbook for Class-IX (Social Science- India and the Contemporary World-I)",
        download:
          "/pdf/History/Textbook for Class-IX (Social Science- India and the Contemporary World-I).pdf",
      },
      {
        title:
          "Textbook for Class-X (Social Science- India and the Contemporary World-II)",
        download:
          "/pdf/History/Textbook for Class-X (Social Science- India and the Contemporary World-II).pdf",
      },
      {
        title: "Textbook for Class-XI (Themes in World History)",
        download:
          "/pdf/History/Textbook for Class-XI (Themes in World History).pdf",
      },
      {
        title: "Textbook for Class-XII (Themes in Indian History – Part-I)",
        download:
          "/pdf/History/Textbook for Class-XII (Themes in Indian History - Part-I).pdf",
      },
      {
        title: "Textbook for Class-XII (Themes in Indian History – Part-II)",
        download:
          "/pdf/History/Textbook for Class-XII (Themes in Indian History - Part-II).pdf",
      },
      {
        title: "Textbook for Class-XII (Themes in Indian History – Part-III)",
        download:
          "/pdf/History/Textbook for Class-XII (Themes in Indian History - Part-III).pdf",
      },
    ],
  },
  {
    title: "Physics",
    totalNo: "Total PDF 4",
    id: "phy",
    PDF: [
      {
        title: "Textbook for Class-XI (Physics – Part-I)",
        download: "/pdf/Physics/Textbook for Class-XI (Physics - Part-I).pdf",
      },
      {
        title: "Textbook for Class-XI (Physics – Part-II)",
        download: "/pdf/Physics/Textbook for Class-XI (Physics - Part-II).pdf",
      },
      {
        title: "Textbook for Class-XII (Physics – Part-I)",
        download: "/pdf/Physics/Textbook for Class-XII (Physics - Part-I).pdf",
      },
      {
        title: "Textbook for Class-XII (Physics – Part-II)",
        download: "/pdf/Physics/Textbook for Class-XII (Physics - Part-II).pdf",
      },
    ],
  },
];

export const affairsNav = [
  {
    title: "Goverment",
  },
  {
    title: "Natural Tragedies",
  },
  {
    title: "Defence",
  },
  {
    title: "Agriculture",
  },
  {
    title: "International",
  },
];

export const CurrentAffair = [
  {
    id: "1",
    title: "Daily CurrentAffairs Magazine - 15th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India  Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "11-07-2024",
    categories: "Government",
    bases: "daily",
    view: "10",
    keyword: ["daily", "Government"],
  },
  {
    id: "2",
    title: "Daily CurrentAffairs Magazine - 16th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "12-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "20",
    keyword: ["weekly", "politics"],
  },
  {
    id: "3",
    title: "Daily CurrentAffairs Magazine - 17th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "13-07-2024",
    categories: "Goverment",
    bases: "weekly",
    view: "5",
    keyword: ["weekly", "culture"],
  },
  {
    id: "4",
    title: "Daily CurrentAffairs Magazine - 18th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "14-07-2024",
    categories: "Goverment",
    bases: "Monthly",
    view: "2",
    keyword: ["daily", "international"],
  },
  {
    id: "5",
    title: "Daily CurrentAffairs Magazine - 19th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "15-07-2024",
    categories: "Goverment",
    bases: "Monthly",
    view: "1",
    keyword: ["monthly", "politics"],
  },
  {
    id: "6",
    title: "Daily CurrentAffairs Magazine - 19th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "16-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["daily", "defence"],
  },
  {
    id: "7",
    title: "Daily CurrentAffairs Magazine - 20th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "17-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["weekly", "government"],
  },
  {
    id: "8",
    title: "Daily CurrentAffairs Magazine - 21th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "18-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["monthly", "culture"],
  },
  {
    id: "9",
    title: "Daily CurrentAffairs Magazine - 22th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "19-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["daily", "international"],
  },
  {
    id: "10",
    title: "Daily CurrentAffairs Magazine - 23th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "20-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["monthly", "trades"],
  },
  {
    id: "11",
    title: "Daily CurrentAffairs Magazine - 23th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "popular",
    date: "21-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["monthly"],
  },
  {
    id: "12",
    title: "Daily CurrentAffairs Magazine - 24th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "22-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["daily"],
  },
  {
    id: "13",
    title: "Daily CurrentAffairs Magazine - 25th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "23-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["weekly"],
  },
  {
    id: "14",
    title: "Daily CurrentAffairs Magazine - 26th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "24-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["daily"],
  },
  {
    id: "15",
    title: "Daily CurrentAffairs Magazine - 27th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "25-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["monthly"],
  },
  {
    id: "16",
    title: "Daily CurrentAffairs Magazine - 28th June 2024",
    desc: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage.`,
    content: `India's northeast area is known for its magnificent landscapes, diversified cultures, and rich tribal heritage. However, effectively incorporating indigenous groups into mainstream development narratives remains a serious difficulty in this region.
Despite its rich natural resources and unique cultural identity, the Northeast has challenges such as poor infrastructure, limited market access, socioeconomic inequality, and recent conflicts such as Manipur. One example of this complexity is the growing of ginger in Karbi Anglong, Assam. A cooperative society attempted to empower indigenous ginger growers, but ultimately failed owing to reasons such as the deterioration of traditional institutions and the domination of exploitive intermediaries.
Challenges with Northeast Region in India
 Insurgency and Ethnic Conflicts - Despite peace agreements with various factions, insurgency remains a problem, particularly in Manipur and parts of Nagaland (desire for autonomy). The recent violence in Manipur (2023) between Meiteis and Kukis demonstrates the fragility of interethnic relations. These conflicts not only jeopardize security, but also stymie development initiatives and foreign investment, perpetuating a vicious cycle of underdevelopment and discontent.  Agricultural Challenges - Despite being an agrarian economy, the Northeast has numerous agricultural issues. Sikkim encouraged organic farming, but its success was limited. Farmers' adoption of organic products is hampered by low premium pricing, certification difficulties, and competition from low-cost, sometimes imported fertilizers. In addition, middlemen continue to be a thorn in Northeast agriculture's side.  Growing Influence of China and Border Disputes - China's claims to Arunachal Pradesh and infrastructural development along the border pose considerable security risks. Recent skirmishes in Tawang (December 2022) and the Dokhlam Region highlight the tension. China's expanding economic influence in Myanmar also threatens to isolate the Northeast, posing a challeng`,
    image: CA,
    tag: "latest",
    date: "26-07-2024",
    categories: "Goverment",
    bases: "daily",
    view: "9",
    keyword: ["daily"],
  },
];

export const CourseDetail = [
  {
    id: "c4",
    src: online,
    type: "upse",
    title: "Csat",
    desc: "Preparing for the Civil Services Aptitude Test (CSAT) is crucial for your IAS journey. Our comprehensive CSAT course is designed to sharpen your logical reasoning, analytical skills, and decision-making abilities. Whether you’re a first-time candidate or need to refine your strategy, AG-IAS offers the right tools to excel.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹11000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 3 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "online",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c5",
    src: online,
    type: "upse",
    title: "GS (PRE + MAINS)",
    desc: "These courses have been carefully designed to cater to your preparation needs aiming to fulfill the gap between Civil Services Examination requirements (IAS/PCS) and the proper preparation strategy. It will cover the syllabus of Prelims and Mains (General Studies) including CSAT and Essay.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹25000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 14 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "online",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c6",
    src: online,
    type: "upse",
    title: "GS (PRE + MAINS + CSAT)",
    desc: "These courses have been carefully designed to cater to your preparation needs aiming to fulfill the gap between Civil Services Examination requirements (IAS/PCS) and the proper preparation strategy. It will cover the syllabus of Prelims and Mains (General Studies) including CSAT and Essay.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹35000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 14 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "online",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c1",
    src: Offline,
    type: "upse",
    title: "Csat",
    desc: "Preparing for the Civil Services Aptitude Test (CSAT) is crucial for your IAS journey. Our comprehensive CSAT course is designed to sharpen your logical reasoning, analytical skills, and decision-making abilities. Whether you’re a first-time candidate or need to refine your strategy, AG-IAS offers the right tools to excel.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹15000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 3 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "offline",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c2",
    src: Offline,
    type: "upse",
    title: "GS (PRE + MAINS)",
    desc: "These courses have been carefully designed to cater to your preparation needs aiming to fulfill the gap between Civil Services Examination requirements (IAS/PCS) and the proper preparation strategy. It will cover the syllabus of Prelims and Mains (General Studies) including CSAT and Essay.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹70000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 14 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "offline",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c3",
    src: Offline,
    type: "upse",
    title: "GS (PRE + MAINS + CSAT) ",
    desc: "These courses have been carefully designed to cater to your preparation needs aiming to fulfill the gap between Civil Services Examination requirements (IAS/PCS) and the proper preparation strategy. It will cover the syllabus of Prelims and Mains (General Studies) including CSAT and Essay.",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹90000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 14 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "offline",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
  {
    id: "c7",
    src: Offline,
    type: "State-PCS",
    title: "Full Course",
    desc: "Your one-stop solution to cracking the State PSC exam starts here! With our Complete State PSC Course, you will be guided step-by-step through the entire process—from the Prelims to the Interview—ensuring a smooth and confident journey to success",
    flow: [
      {
        icon: clock,
        content: "Course Validity: Till July 2025",
      },
      {
        icon: money,
        content: "Fee: ₹90000/- Hindi & English medium",
      },
      {
        icon: duration,
        content: "Duration: 14 Months",
      },
      {
        icon: timing,
        content: "Course Timing: 10:30 - 1:00 & 16:30 - 19:00",
      },
    ],
    about:
      "IAS Live Online Classes are conducted live via the AG IAS Learning App which can be installed from the Google Play Store for free. These classes are available in both English and Hindi medium and are a great way to begin UPSC preparation.",
    teacher: {
      name: "Amit K Srivastva",
      subject: "IAS/IPS",
      expirence: "25+ year",
    },
    mode: "offline",
    language: "Hindi",
    studyHours: "4 to 6 hour per day monday to saturday",
  },
];

export const ProgramStructure = [
  {
    id: "1",
    course: "c1",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
  {
    id: "2",
    course: "c2",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
  {
    id: "3",
    course: "c3",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
  {
    id: "4",
    course: "c4",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
  {
    id: "5",
    course: "c5",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
  {
    id: "6",
    course: "c6",
    PhaseData: [
      {
        title: "Phase-1",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-2",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiReceiveMoney,
      },
      {
        title: "Phase-3",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiDuration,
      },
      {
        title: "Phase-4",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: GiRingingAlarm,
      },
      {
        title: "Phase-5",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: PiClockCountdownFill,
      },
      {
        title: "Phase-6",
        des: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.",
        Icon: LiaSearchDollarSolid,
      },
    ],
  },
];

export const courseFeature = [
  {
    id: "1",
    course: "c1",
    features: [
      {
        title: "coverage with over 1000 hours of classes.",
      },
      {
        title: "conceptual and effective answer writing skills.",
      },
      {
        title: "Practice sessions include previous years' questions",
      },
      {
        title: "Dedicated doubt resolution ",
      },
      {
        title: "Free Course materials",
      },
      {
        title: "Notes and class handouts by the Experts.",
      },
    ],
  },
];

export const WCOCP = [
  "One on One Interaction with the Faculty ",
  "Free Library facility",
  "20+ years experienced faculty.",
  "Smart classes with modern facilities.",
];

export const AdmissionDetails = [
  "Passport Size Photo",
  "Aadhar Number/ PAN Number",
  "Address (permanent & correspondence)",
  "Educational qualification (10th, 12th, Graduation, Post-Graduation, etc).",
  "Photocopy of photo identity (PAN card, Aadhar card, etc.)",
];

export const Additional = {
  desc: "At AG-IAS, we go beyond just teaching—you get a mentorship-driven learning experience with top IAS faculty and a proven track record of success. With a curriculum aligned to the latest CSAT trends, you’ll build a strong foundation to clear the exam with confidence.",
  Benifits: [
    "Free Current Affairs classes.",
    "Complimentary UPSC CSE Prelims Online Test Series: 25 tests (22 General Studies + 3 CSAT).",
    "24 answer writing practice tests covering all subjects/modules.",
  ],
};

export const VideoArray = [
  {
    title: "भारत का अगला प्रधानमंत्री कौन?",
    src: thumb1,
    routes: "https://youtu.be/BNVRPKHm8UI?si=giCaDSY0YCvkn-bh",
  },
  {
    title: "किसान हो जाये सावधान ...मोदी ने दी चेतावनी",
    src: thumb2,
    routes: "https://youtu.be/B8JUfzF-5e0?si=2Mbpjg-15P62mSMf",
  },
  {
    title: "कुवैत में 49 लोगो की मौत",
    src: thumb3,
    routes: "https://youtu.be/hnZDv_6myO4?si=5ggp08pFnhlzwj7K",
  },
  {
    title: "बड़ी खुशखबरी Petrol - Diesel",
    src: thumb4,
    routes: "https://youtu.be/eLoXCKPFl7c?si=1pCzzqf_SrURzPti",
  },
  {
    title: "सरकार देगी 1 Lakh रुपए",
    src: thumb5,
    routes: "https://youtu.be/q2zpAbOGoYk?si=Q8H_-OD2xZU7TgC6",
  },
];

export const Whats_New_Data = [
  { title: "Admission Open (2025-2026)", routes: "/registration" },
  { title: " Weekend batch starting on September 8, 2024.", routes: "#" },
];

export const OurTeacher = [
  {
    ImageSrc: AmitSrivastava,
    name: "Amit Srivastava Sir",
    subject: "Economics",
    Experience: "11+ Yrs Experience In Economics Teaching.",
  },
  {
    ImageSrc: AnikKumar,
    name: "Anik Kumar Sir",
    subject: "Geography",
    Experience: "8+ Yrs experience in Geography teaching",
  },
  {
    ImageSrc: AvinashGautam,
    name: "Avinash Gautam Sir",
    subject: "Reasoning",
    Experience: "8-10 Yrs experience in Reasoning Teaching",
  },
];

export const ourTeam = [
  {
    ImageSrc: Sheetal,
    name: "Sheetal Chauhan",
    subject: "Academic Counsellor",
  },
  {
    ImageSrc: Durgesh,
    name: "Durgesh kumar",
    subject: "Digital Marketing Team Leader",
  },
];
