import React, { FC } from 'react';
import Input from './Input/input';
import { RxCross2 } from 'react-icons/rx';

interface AuthProp {
 handleClick?: any;
 title?: string;
 Btitle?: string;
}

const PopUpComponent:FC<AuthProp> = ({handleClick, title, Btitle}) => {

    return (
        <div className='fixed z-10 h-full w-full bg-black/75 top-0'>
            <div className='w-full h-full fixed z-[99] bg-[#F0F3F4] md:bg-transparent md:mt-8'>
                <div className='flex justify-center'>
                    <div className='
                    relative
                    bg-white
                    shadow-md
                    px-16 
                    py-16
                    self-center 
                    mt-2 
                    mb-4
                    lg:w-2/5 
                    lg:max-w-md
                    rounded-md
                    w-full
                    '>
                        <RxCross2 className='top-3 right-3 h-[2rem] w-[2rem] absolute' onClick={handleClick} />
                        <h1 className='text-black text-2xl mb-8 font-semibold'>
                           {title?title:'Free Demo Class'}
                        </h1>
                        <div className='flex flex-col my-2 gap-4'>
                            <Input
                                type='text'
                                value=''
                                label='Full Name'
                                id='Full Name'
                                onCharge={() => { }}
                            />
                        </div>
                        <div className='flex flex-col gap-4'>
                            <Input
                                type='email'
                                label='Email'
                                id='email'
                                onCharge={() => { }}
                                value={''} />
                        </div>
                        <div className='flex flex-col my-2 gap-4'>
                            <Input
                                type='text'
                                value=''
                                label='Phone No'
                                id='Phone No'
                                onCharge={() => { }}
                            />
                        </div>
                        <div className='flex flex-col my-2 gap-4'>
                            <Input
                                type='text'
                                value=''
                                label='Address'
                                id='address'
                                onCharge={() => { }}
                            />
                        </div>
                        <button className='bg-primary py-2 text-white rounded-md w-full mt-10 hover:bg-blue-800 transition'>
                            {Btitle?Btitle:'Apply for demo class'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopUpComponent;
