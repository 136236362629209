import React, { FC } from 'react';
import { newspaper } from '../../utils/images';

interface bannerProps {
    classCSS? : string
    url? : string
}

const Banner2Component: FC<bannerProps> = ({classCSS, url}) => {
    return (
        <img src={url?url:newspaper} alt="banner" className={classCSS + ` w-full`} />
    );
}

export default Banner2Component;
