import React, { FC } from "react";

interface CardContainerProps {
  title1?: string;
  child1: React.ReactNode;
  child2?: React.ReactNode;
}

const CardContainerComponent: FC<CardContainerProps> = ({
  title1,
  child1,
  child2,
}) => {
  return (
    <div className="w-full flex flex-col items-center justify-center my-10 ">
      <span className="text-xl font-semibold capitalize">
        {title1 ? title1 : "UPSC Course Fee Structure"}
      </span>
      <span className="text-xl text-center md:text-left md:text-3xl font-bold capitalize">
        Right Course For you Better Result - Offline fee
      </span>
      <div className="flex flex-col items-center justify-center md:flex-row w-full gap-4 my-4">
        {child1}
      </div>
      {child2 && (
        <span className="text-xl md:text-3xl text-center md:text-left font-bold capitalize mt-4">
          Right Course For you Better Result - online fee
        </span>
      )}
      <div className="flex flex-col items-center justify-center md:flex-row w-full gap-4 my-4">
        {child2}
      </div>
    </div>
  );
};

export default CardContainerComponent;
