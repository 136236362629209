import React from "react";

interface AddressDetailsCompnentProp {
  Title: string;
}

const AddressDetailsComponent: React.FC<AddressDetailsCompnentProp> = ({
  Title,
}) => {
  return (
    <div className="w-full mt-8">
      <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        {Title}
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <div className="flex w-full flex-col items-start gap-2">
          <label htmlFor="Premanent" className="text-lg font-semibold">
            Premanent Address <span className="text-red-600">*</span> :-
          </label>
          <input
            autoComplete="off"
            type="text"
            id="Premanent"
            name="Premanent"
            className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md w-full"
            required
          />
        </div>
      </div>
      <div className="flex w-full items-center flex-wrap lg:flex-nowrap mt-4 gap-12 lg:gap-32 p-4">
        <div className="flex w-1/3 flex-col items-start gap-2">
          <label htmlFor="City" className="text-lg font-semibold">
            City <span className="text-red-600">*</span> :-
          </label>
          <input
            autoComplete="off"
            type="text"
            id="City"
            name="City"
            className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md w-full"
            required
          />
        </div>
        <div className="flex w-1/3 flex-col items-start gap-2">
          <label htmlFor="State" className="text-lg font-semibold">
            State <span className="text-red-600">*</span> :-
          </label>
          <input
            autoComplete="off"
            type="text"
            id="State"
            name="State"
            className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md w-full"
            required
          />
        </div>
        <div className="flex w-1/3 flex-col items-start gap-2">
          <label htmlFor="Pincode" className="text-lg font-semibold">
            Pincode <span className="text-red-600">*</span> :-
          </label>
          <input
            autoComplete="off"
            type="text"
            id="Pincode"
            name="Pincode"
            className="px-2 py-1 outline-none bg-[#f3f3f3] rounded-md w-full"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default AddressDetailsComponent;
