import React from 'react'
import { Notfound } from '../utils/images'

const NotFound = () => {
  return (
    <div className='h-screen w-full flex flex-col items-center justify-center'>
      <h1 className='font-bold text-4xl'>NOT FOUND</h1>
      <img src={Notfound} alt="404" className='h-[30rem]'/>
    </div>
  )
}

export default NotFound