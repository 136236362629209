import React from "react";

const UploadComponent = () => {
  return (
    <div className="w-full mt-8">
      <div className="w-full bg-[#f7f7f7] rounded-md p-2 text-xl font-semibold">
        Upload Image/ signature :-
      </div>
      <div className="flex items-center flex-wrap lg:flex-nowrap gap-6 md:gap-12 lg:gap-28 mt-8">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
          <label
            htmlFor="myFile"
            className="text-base md:text-lg font-semibold"
          >
            Upload Photo <span className="text-red-600">*</span> :-
          </label>
          <input type="file" id="myFile" name="filename" />
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
          <label
            htmlFor="myFile"
            className="text-base md:text-lg font-semibold"
          >
            Upload Signature <span className="text-red-600">*</span> :-
          </label>
          <input type="file" id="myFile" name="filename" />
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
