import React from 'react';
import AlertComponent from '../components/alert.component';
import NavbarComponent from '../components/navbar.component';
import { Outlet } from 'react-router-dom';
import FooterComponent from '../components/footer.component';
// import AuthComponent from '../components/auth.component';
const RootLayout = () => {
    return (
        <>
          <AlertComponent/>
          <NavbarComponent/>
          <Outlet/>
          {/* <AuthComponent/> */}
          <FooterComponent/>
        </>
    );
}

export default RootLayout;
