import React, { useState } from "react";
import { Call } from "../utils/icons";
import { IoIosCall } from "react-icons/io";
import PopUpComponent from "./popup.component";

const HaveanyqueryComponent = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(() => !open);
  };

  return (
    <>
      <div className="w-full h-fit bg-primary flex flex-col lg:flex-row p-6 mt-6 mb-8 shadow-xl items-center">
        <div className="w-full lg:w-[70%] flex gap-5 items-center justify-center lg:gap-[8rem] ">
          <img src={Call} alt="query" className="h-[5rem] lg:h-[10rem]" />
          <div>
            <span className="text-[1.5rem] lg:text-[3rem] capitalize text-white font-bold">
              have any query?
            </span>
            <p className="text-white text-xl">
              Our support team will be happy to assist you!
            </p>
          </div>
        </div>
        <hr className="block lg:hidden bg-white w-full mt-4" />
        <div className="w-full lg:w-[30%] flex flex-col items-center justify-center gap-4 ">
          <div className="flex items-center mt-4 md:mt-0">
            <div className="rounded-full bg-white w-fit h-fit p-2 ">
              <IoIosCall className="h-4 lg:h-8 w-4 lg:w-8" />
            </div>
            <div className="flex flex-col">
              <span className="text-white text-base lg:text-lg ml-3 capitalize font-sans">
                call us
              </span>
              <span className="text-white text-base lg:text-lg ml-3 font-bold">
                +91-9289298044
              </span>
              <span className="text-white text-base lg:text-lg ml-3 font-bold">
                +91-9289298047
              </span>
            </div>
          </div>
          <span className="hidden lg:flex items-center text-white text-center">
            ---------------<span className="text-xl text-center">OR</span>
            --------------
          </span>
          <div className="w-fit lg:mt-4">
            <button
              onClick={toggle}
              className="text-black bg-white capitalize py-1 px-4 lg:px-12 rounded-xl font-bold"
            >
              get a call from us
            </button>
          </div>
        </div>
      </div>
      {open && (
        <PopUpComponent
          handleClick={toggle}
          title="Get a call from us"
          Btitle="We will connect you soon"
        />
      )}
    </>
  );
};

export default HaveanyqueryComponent;
