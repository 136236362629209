import React from "react";
// import Banner2Component from "../components/Banner/banner2.component";
// import { limitLess } from "../utils/images";
// import SearchComponent from '../components/search.component';
import CardContainerComponent from "../components/courseComponents/cardContainer.component";
import { courses } from "../dummy data/dummy.data";
import PriceCard from "../components/Cards/price.card";
import HaveanyqueryComponent from "../components/haveanyquery.component";
import AdvertisingComponent from "../components/OurAdversting.componet";

const CoursePage = () => {
  return (
    <div className="w-full">
      {/* <Banner2Component url={limitLess} classCSS='h-[10rem] md:h-[15rem] mb-6' /> */}
      <AdvertisingComponent />
      <div className="flex w-full gap-2 mb-6">
        <div className="hidden lg:block w-1/5"></div>
        <div className="w-full lg:ml-0 lg:w-3/5">
          {/* <SearchComponent classCSS='w-full'/> */}
          {courses.map((e, index) => (
            <CardContainerComponent
              key={index}
              title1={e.courseName}
              child1={e.offline.map((e, index) => (
                <PriceCard
                  key={index}
                  types={e.name}
                  price={e.price}
                  mode={e.Mode}
                  access={e.access}
                  completion={e.completion}
                  support={e.support}
                  profit={e.profit}
                  id={e.course}
                />
              ))}
              child2={e.online?.map((e, index) => (
                <PriceCard
                  key={index}
                  types={e.name}
                  price={e.price}
                  mode={e.Mode}
                  access={e.access}
                  completion={e.completion}
                  support={e.support}
                  profit={e.profit}
                  id={e.course}
                />
              ))}
            />
          ))}
        </div>
        <div className="hidden lg:block w-1/5"></div>
      </div>
      <HaveanyqueryComponent />
    </div>
  );
};

export default CoursePage;
