import React, { FC } from "react";
import { online } from "../../utils/images";
import NormalButton from "../Buttons/normal.button";

interface cardProp {
  title?: string;
  hours?: string;
  src?: string;
  fee?: string;
  teacher?: any;
  duration?: string;
  timing?: string;
  route?: string;
  ButtonName?: string;
}

const CourseCard: FC<cardProp> = ({
  title,
  hours,
  src,
  fee,
  teacher,
  duration,
  timing,
  route,
  ButtonName,
}) => {
  return (
    <div className="bg-white rounded-3xl mt-0 w-fit h-fit pb-6 shadow-xl">
      <img src={src ? src : online} alt="thumbnail" className="rounded-3xl" />
      <div className="flex flex-col items-center">
        <p className="text-[13px]">
          <span className="font-bold mr-1">Study Hours:</span>
          {hours ? hours : "4 to 6 hour per day monday to saturday"}
        </p>
        <span className=" font-bold text-2xl uppercase">
          {title ? title : "complete course"}
        </span>
        <p className="font-bold text-md w-[16rem] flex capitalize flex-col items-center">
          <span>{fee ? fee : "$500/- english medium"}</span>
          {/* <span>&nbsp;$450/- hindi mediun </span> */}
        </p>
        <div className="w-full bg-primary text-white flex flex-col items-center text-sm mt-4 leading-4 p-1">
          <div className=" capitalize">
            mentorship from {teacher ? teacher.name : "Amit shrivastava"}
          </div>
          <div className=" capitalize">
            {teacher ? teacher.subject : "IAS/IPS"} Professor Proven study
            Material
          </div>
          <div className=" capitalize">
            Proven track record - {teacher ? teacher.expirence : "25+ year"}
          </div>
        </div>
      </div>
      <div className="my-1 p-4">
        <p className="flex gap-2 items-center font-bold">
          {duration ? duration : "Duration:"}
        </p>
        {/* <p className='flex gap-2 items-center font-bold'>{timing?timing:'Timing'}</p> */}
      </div>
      <div className="w-full mt-1 flex justify-center">
        <NormalButton
          classCss="text-white flex items-center font-semibold justify-center p-4 md:p-3 h-[1rem] md:h-fit w-[8rem] text-[8px] rounded-full"
          route={route ? route : "#"}
        >
          {ButtonName ? ButtonName : "Buy Now"}{" "}
        </NormalButton>
      </div>
      <div className="flex flex-col items-center justify-center w-full mt-4 text-sm font-semibold gap-1 px-2">
        <p className="w-full text-center">Special features</p>
        <p className="w-full text-sm font-bold text-center">
          WEEKEND BATCHES ALSO AVAILABLE
        </p>
      </div>
    </div>
  );
};

export default CourseCard;
