import React, { FC } from 'react';
import { student } from '../../utils/images';
import Timer from '../Clock/Timer.clock';
import NormalButton from '../Buttons/normal.button';
import { Admissiom } from '../../utils/icons';

interface TimerProps {
    targetDate: Date; // Date and time to count down to
  }

const AdmissionComponent: FC<TimerProps> = ({targetDate}) => {
    return (
        <div className='w-full md:w-full h-fit bg-white flex flex-col-reverse md:flex-row items-center justify-center gap-4 shadow-lg'>
            <img src={student} alt="student" className='h-[300px] w-[300px] hidden lg:block' />
            <div className=' md:w-[40rem] flex flex-col justify-center items-center'>
                <span className=' font-bold text-2xl capitalize'>informataion timing</span>
                <Timer targetDate={targetDate} />
                <p className='text-center text-[10px] w-[25rem]'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate, vel libero, veniam, eligendi corrupti voluptate officia quia eius odit fugiat nisi id dolores iusto?</p>
                <NormalButton classCss='text-white w-fit px-4 py-2 rounded-xl m-2 md:mt-2' route='/'>Connect</NormalButton>
            </div>
            <img src={Admissiom} alt="Admission logo" className='h-[10rem] md:h-[18rem] w-[10rem] md:w-[18rem]' />
        </div>
    );
}

export default AdmissionComponent;
