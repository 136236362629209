import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface cssClass {
    classCss?: string;
    route: string;
    children: React.ReactNode;
}

const NormalButton: FC<cssClass> = ({ classCss, route, children }) => {

    return (
        <Link
            target='blank'
            to={route}
            className={classCss + ` text-lg bg-primary`}
        >{
                children
            }</Link>
    );
}

export default NormalButton;
