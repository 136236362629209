import React from "react";
import { white } from "../utils/icons";
// import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <footer className="w-full py-5 bg-primary">
      <div className=" w-full lg:w-4/5 lg:m-auto">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
          <div className=" w-full lg:w-1/2 flex flex-col items-center lg:items-start gap-y-4">
            <Link className="flex items-center" to={"/"}>
              <img src={white} alt="Logo" className="h-[70px]" />
              <span className="text-white text-[5.5rem] leading-3 font-semibold">
                IAS
              </span>
            </Link>
            <p className="w-fit md:w-[30rem] text-center lg:text-start lg:w-[30rem] text-white">
              "Today's Aspirant, Tomorrow's Achiever"
            </p>
            {/* <div className='w-full hidden md:flex justify-center lg:justify-start items-center gap-4'>
                            <GooglePlayButton url='/' theme='dark' />
                            <AppStoreButton url='/' theme='dark' />
                        </div> */}
          </div>
          <div className=" w-full lg:w-1/2 flex items-start justify-center mt-4 lg:mt-0 lg:justify-end gap-8">
            <div>
              <span className=" capitalize text-xl font-bold text-white">
                Quick Links
              </span>
              <div className="flex flex-col items-start justify-center text-white text-sm gap-1">
                <Link to={"/courses"}>Courses</Link>
                <Link to={"/ncert-books"}>Ncert Books</Link>
                <Link to={"/main-previous-year-papers"}>Main Previous</Link>
                <Link to={"/prelims-previous-year-papers"}>
                  Prelims Previous
                </Link>
              </div>
            </div>
            {/* <div>
                            <span className=' capitalize text-xl font-bold text-white'>Legal</span>
                            <div className='flex flex-col items-center justify-center text-white text-sm'>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                                <span>refund policy</span>
                            </div>
                        </div> */}
            <div>
              <span className=" capitalize text-xl font-bold text-white">
                support
              </span>
              <div className="flex flex-col items-start justify-center text-white text-sm">
                <Link to={"/about-us"}>About us</Link>
                <Link to={"/contact-us"}>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full my-4"></hr>
        <div className="flex flex-col-reverse lg:flex-row w-full items-center justify-between mt-4">
          <div className="flex items-center justify-center mt-4 lg:mt-0 gap-0 lg:gap-1">
            {/* <img src={white} alt="logo" className=' hidden lg:block h-12' /> */}
            <p className="text-white text-center lg:text-left">
              Copyright &copy; 2010-2024 AGIAS Company S.L. All right reserved
            </p>
          </div>
          <div className="flex gap-2 right-0 md:right-10">
            <SocialIcon
              url="https://www.facebook.com/BestIASCoachinginNoida/"
              target="blank"
            />
            <SocialIcon
              url="https://www.instagram.com/agias_greaternoida/"
              target="blank"
            />
            <SocialIcon
              url="https://www.linkedin.com/company/ag-ias-grnoida"
              target="blank"
            />
            <SocialIcon url="https://www.youtube.com/@AG_IAS" target="blank" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
