import React, { useState } from 'react';
import { AGIASLOGO } from '../utils/icons';
import { Link, NavLink } from 'react-router-dom';
import { NavRoutes } from '../dummy data/dummy.data';
import { FaList } from "react-icons/fa";
import SidebarComponent from './sidebar.component';
import PopUpComponent from './popup.component';
// import { useTranslation } from 'react-i18next';

const NavbarComponent = () => {

    const [open, setOpen] = useState<boolean>(false);
    const[auth, setAuth] = useState(false);
    const [focusedNavTitle,setFocusedNavTitle] = useState<string>('');
    // const {t} = useTranslation();

    // console.log(t('main.lol'))

    const toggle = () => {
        setOpen(() => { return !open; })
    }

    const showSubNav = (str:string) => {
        if(focusedNavTitle === str) {
            const filterNav = NavRoutes.filter(el=>el.subNav.length>0 && el.navTitle===str);
            if(filterNav.length){
                setFocusedNavTitle(()=>'');
                filterNav[0].isHover=false;
            }
        }else{
            const previousFilteredNav = NavRoutes.filter(el=>el.subNav.length>0 && el.navTitle===focusedNavTitle);
            if(previousFilteredNav.length){
                previousFilteredNav[0].isHover = false;
            }
            const filterNav = NavRoutes.filter(el=>el.subNav.length>0 && el.navTitle===str);
            if(filterNav.length){
                setFocusedNavTitle(()=>str);
                filterNav[0].isHover=true;
            }
            
        }
    }

    const Auth = ()=>{
        setAuth(()=>!auth)
    }
    
    return (
        <>
            <nav className='flex relative items-center justify-between px-2 shadow-3xl'>
                <div className='flex items-center'>
                    <Link to={'/'}><img src={AGIASLOGO} alt="agias logo" className='h-10 md:h-16' /></Link>
                    <Link
                        to="#"
                        className="animate-pulse bg-primary text-amber-200 text-sm p-0 md:p-1 rounded-full outline outline-2 outline-offset-4 uppercase outline-red-500"
                    >What's new</Link>
                </div>
                <div className='flex items-center'>
                    <ul className='hidden lg:flex items-center'>
                        {
                            NavRoutes.map((nav, index) => (
                                <li key={index} className='capitalize p-3'>
                                    <NavLink
                                        to={nav.subNav.length>0?'#':nav.link}
                                        className={({ isActive }) =>
                                            isActive && !nav.subNav.length
                                                ? "border-b-2 pb-2 border-[#dd000e] text-[#dd000e]  font-medium text-lg"
                                                : "hover:border-b-2 hover:pb-2 hover:border-[#dd000e] hover:text-[#dd000e] text-gray-500 font-medium text-lg"
                                        }

                                        onClick={()=>showSubNav(nav.navTitle)}
                                    >
                                        {nav.navTitle}
                                    </NavLink>
                                    <ul className="absolute z-10 bg-white shadow-md w-[15rem] top-16">
                                    {(nav.isHover) && nav.subNav.map((subNav, index) => (
                                        <li key={index} className='border-b-2 p-1 shadow-md'>
                                            <NavLink to={subNav.link} className="px-4 py-2 text-sm text-gray-700">{subNav.navTitle}</NavLink>
                                        </li>
                                    ))}
                                    </ul>
                                </li>
                            ))
                        }
                    </ul>
                    <button onClick={Auth} className='text-white hidden bg-primary lg:block font-normal md:font-medium p-1 md:p-3 rounded-xl md:rounded-2xl animate-pulse capitalize'>Free Demo class</button>
                    <button onClick={toggle} className='block bg-primary lg:hidden rounded-lg px-2 py-1'>
                        <FaList className='invert' />
                    </button>
                </div>
            </nav>
            {
                open && <div className='bg-black opacity-90 w-full h-screen transition-all ease-in duration-300 fixed z-30 top-0' onClick={toggle}></div>
            }
            {open ? <SidebarComponent classCSS='block transition-all duration-800' handleClick={toggle} /> : <SidebarComponent classCSS='hidden transition-all ease-in-all' handleClick={toggle} />}
            {auth && <PopUpComponent handleClick={Auth} />}
        </>
    );
}

export default NavbarComponent;
