import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdDownload } from "react-icons/io";
import { pdf1 } from "../../utils/images";

interface DuelProps {
  paper: string;
  year?: string;
  pdfRoute?: string;
  classCSS?: string;
  route?: string;
}

const PdfButton: FC<DuelProps> = ({
  paper,
  year,
  pdfRoute,
  classCSS,
  route,
}) => {
  let full_paper = paper.length;

  let full_title = paper;

  const [hover, setHover] = useState(false);

  const ishover = () => {
    if (full_paper > 10) {
      setHover(() => !hover);
    }
  };

  (function trim() {
    let lenght = paper.length;
    if (lenght > 10) {
      let a = paper.split(" ");
      if (a.length > 2) {
        paper = a[0] + " " + a[1] + "...";
      } else {
        paper = a[0] + " ...";
      }
      return paper;
    }
  })();

  return (
    <div className="relative flex gap-2 lg:gap-4 m-2 justify-start">
        <button
          className="flex rounded-xl p-2 md:gap-6 items-center bg-blue-300 w-[10rem] md:w-[15rem]"
          onMouseEnter={ishover}
          onMouseLeave={() => setHover(false)}
        >
          <div
            className={
              classCSS +
              " rounded-full flex items-center justify-center"
            }
          >
            <img
              src={pdf1}
              alt="pdf"
              className="h-8 md:h-11"
            />
          </div>
          <div className="flex flex-col items-cenetr justify-center bg-transparent p-2">
            <span className="text-xs md:text-sm lg:text-base font-bold text-center leading-4">
              {paper ? paper : `Gs paper`}
            </span>
            <span className="text-xs md:text-sm lg:text-base font-bold text-center">
              {year ? year : `2023`}
            </span>
          </div>
          {
            pdfRoute &&
          <Link to={pdfRoute ? pdfRoute : ""} target="_blank">
            <IoMdDownload className="text-sm md:text-base lg:text-xl hover:text-white" />
          </Link>
          }
        </button>
      {hover && (
        <div className="absolute z-10 left-60 w-[17rem] h-fit rounded-2xl bg-neutral-950/50 p-2 md:p-4">
          <p className="text-sm text-white w-full">{full_title}</p>
        </div>
      )}
    </div>
  );
};

export default PdfButton;
