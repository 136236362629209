import Banner2Component from "../components/Banner/banner2.component";
import CourseCard from "../components/Cards/course.card";
import HaveanyqueryComponent from "../components/haveanyquery.component";
// import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { clock, IAS_FREE } from "../utils/images";
import { GoDotFill } from "react-icons/go";
import {
  Additional,
  AdmissionDetails,
  CourseDetail,
  courseFeature,
} from "../dummy data/dummy.data";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { IdChecker } from "../utils/Cid";

const CourseDetailsPage = () => {
  const { id } = useParams();
  const Data = CourseDetail.filter((user) => user.id === id);
  // const Phase = ProgramStructure.filter(user => user.course === id);
  // const Feature = courseFeature.filter(user => user.course === id);
  const Cid = IdChecker(CourseDetail);

  if (!Cid.includes(id)) {
    return <NotFound />;
  }

  return (
    <>
      <Banner2Component classCSS="h-[10rem] md:h-[15rem]" />
      <div className="my-6 w-full flex justify-center">
        <div className=" w-full px-2 lg:px-0 lg:w-[80%]">
          {Data.map((e, index) => (
            <div
              key={index}
              className="flex flex-col-reverse md:flex-row gap-2 w-full h-fit"
            >
              <div className="w-full md:w-[80%]">
                <span className="text-2xl font-bold uppercase">
                  {e.title ? e.title : "UPSC Full Course"}
                </span>
                <p className="mt-3">
                  {e.desc
                    ? e.desc
                    : "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio necessitatibus accusamus, ea quas commodi dicta sit, veniam doloribus maxime sed, quae molestiae esse non eos minus sequi? Aliquid, eaque labore autem iure reiciendis non exercitationem?"}
                </p>
                <div className="grid grid-cols-2 my-10 gap-4">
                  {e.flow.map((e, index) => (
                    <div
                      key={index}
                      className="col-span-1 flex items-start gap-1"
                    >
                      <div>
                        <img
                          src={e.icon ? e.icon : clock}
                          alt="info"
                          className="w-[2rem] h-[2rem]"
                        />
                      </div>
                      <div className="text-lg font-bold">
                        {e.content
                          ? e.content
                          : "Course Validity: Till July 2025"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-[95%]">
                  <span className="font-bold text-2xl">
                    About{" "}
                    <span className="uppercase">
                      {e.title ? e.title : "Title"}
                    </span>
                  </span>
                  <p className="mt-3">
                    {e.about
                      ? e.about
                      : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero impedit fuga assumenda esse ad quas officia, distinctio nobis ut quos magni exercitationem quaerat veritatis dolorem quasi dolorum eligendi. Voluptate doloribus necessitatibus laudantium aut? Non, enim."}
                  </p>
                </div>
              </div>
              <CourseCard
                title={e.title}
                hours={e.studyHours}
                fee={e.flow[1].content}
                teacher={e.teacher}
                src={e.src}
                duration={e.flow[2].content}
                timing={e.flow[3].content}
                route={"/registration"}
              />
            </div>
          ))}
          {/* <div className='w-full'>
                        <div className='w-full flex flex-col justify-center'>
                            <span className='text-2xl font-bold'>Program Structure</span>
                            {
                                Phase.map((e, index) => (
                                    <div key={index}>
                                        {
                                            e.PhaseData.map((e, index) => (
                                                <div key={index} className={`${index % 2 === 1 ? 'flex justify-end' : 'flex justify-start'} md:w-[90%] m-auto`}>
                                                    <div className={index % 2 === 1 ? 'lg:w-[35vw] flex flex-row-reverse items-center justify-end gap-3 lg:gap-5 rounded-full border-2 border-dashed lg:pr-8 mt-5' : 'lg:w-[35vw] flex items-center justify-start gap-3 lg:gap-5 rounded-full border-2 border-dashed lg:pl-16 mt-5'}>
                                                        <div className={`${index % 2 === 1 ? 'items-start' : 'items-end'} flex flex-col md:w-[18rem]`}>
                                                            <span className='bg-[#b2382d] w-fit text-white font-semibold px-4'>{e.title ? e.title : 'Phase-1'}</span>
                                                            <p className={`${index % 2 === 1 ? 'text-start' : 'text-end'} text-[0.5rem] md:text-xs leading-3 md:leading-4`}>{e.des ? e.des : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam cumque nemo ut libero porro voluptas, consequuntur labore corrupti aut debitis illum, dolor soluta hic.'}</p>
                                                        </div>
                                                        <div className='bg-blue-400 rounded-full p-1 md:p-2'>
                                                            <div className='rounded-full p-4 md:p-5 bg-[#f9f9f9] shadow-[0_0px_10px_1.5px_rgba(0,0,0,0.5)]'>
                                                                <e.Icon className='h-[2rem] w-[2rem] md:h-[3rem] md:w-[3rem] ' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
          <div className="w-full my-8">
            <Banner2Component
              classCSS="h-[10rem] md:h-[15rem]"
              url={IAS_FREE}
            />
          </div>
          <div className="my-8 flex flex-col items-center">
            <p className="font-bold text-2xl w-full text-start">
              Course Features
            </p>
            <div>
              {courseFeature.map((e, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-4 mt-8 flex-wrap"
                >
                  {e.features.map((e, index) => (
                    <div
                      key={index}
                      className="bg-gradient-to-t from-[#112a48] via-[#294766] col-span-1 to-[#466987] flex flex-col items-center justify-center rounded-xl py-4 px-8 w-[14rem] h-[14rem]"
                    >
                      <span className="text-white text-xl text-center font-medium">
                        {e.title
                          ? e.title
                          : "Comprehensive coverage with over 1000 hours of classes."}
                      </span>
                      {/* {e.point && <ul className='text-white flex flex-col items-start mt-3 gap-3'>
                                                        {
                                                            e.point.map((Item, index) => (
                                                                <li key={index} className='flex items-start leading-4 mb-1 text-sm'><GoDotFill className='w-[1.5rem] h-[1.5rem]' />{Item ? Item : 'Lorem ipsum dolor sit amet.'}</li>
                                                            ))
                                                        }
                                                    </ul>} */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full my-12">
            <span className="text-2xl font-bold">Why Choose AG-IAS?</span>
            <p className="my-8 font-semibold">{Additional.desc}</p>
            {Additional.Benifits.map((e, index) => (
              <div
                key={index}
                className="w-full flex items-center my-4 gap-2 md:gap-6"
              >
                <div>
                  <GoDotFill className="text-[#bc0303] w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem]" />
                </div>
                <p className="text-base font-semibold">
                  {e
                    ? e
                    : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ratione vero possimus, quis minus recusandae laudantium nemo dignissimos omnis soluta nam, quasi enim hic, quisquam odio! Rerum fugit similique aperiam accusantium et."}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="w-full my-8">
            <span className="text-2xl font-bold"></span>
            {WCOCP.map((e, index) => (
              <div
                key={index}
                className="w-full flex items-center my-4 gap-2 md:gap-6"
              >
                <div>
                  <IoMdCheckmarkCircleOutline className="text-[#bc0303] w-[1.5rem] h-[1.5rem] md:w-[2rem] md:h-[2rem]" />
                </div>
                <p className="text-xl font-semibold md:leading-4">
                  {e
                    ? e
                    : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ratione vero possimus, quis minus recusandae laudantium nemo dignissimos omnis soluta nam, quasi enim hic, quisquam odio! Rerum fugit similique aperiam accusantium et."}
                </p>
              </div>
            ))}
          </div> */}
          <div className="w-full my-12">
            <span className="text-2xl font-bold">Admission Details</span>
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {AdmissionDetails.map((e, index) => (
                <div
                  key={index}
                  className="w-full flex items-center my-4 gap-2 md:gap-6"
                >
                  <div>
                    <GoDotFill className="text-[#bc0303] w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem]" />
                  </div>
                  <p className="text-lg font-semibold">
                    {e
                      ? e
                      : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ratione vero possimus, quis minus recusandae laudantium nemo dignissimos omnis soluta nam, quasi enim hic, quisquam odio! Rerum fugit similique aperiam accusantium et."}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <HaveanyqueryComponent />
    </>
  );
};

export default CourseDetailsPage;
