import React from 'react';
import Marquee from "react-fast-marquee";
import { AlertData } from '../dummy data/dummy.data';
import { Link } from 'react-router-dom';

const AlertComponent = () => {
    return (
        <Marquee className="bg-[#1328AF] py-1 md:py-1">
            <div className="flex flex-row items-center z-20">
                {
                    AlertData.map((alert, index) => (
                        <Link key={index} to={alert.link} className="text-white uppercase font-bold ms-3">
                            {alert.description}
                        </Link>
                    ))
                }
            </div>
        </Marquee>
    );
}

export default AlertComponent;
