import React from "react";

interface Props {
  Title: string;
  type: string;
  name: string;
  placeHolder?: string;
}

const FormInput: React.FC<Props> = ({ Title, type, name, placeHolder }) => {
  return (
    <div className="flex w-1/3 flex-col items-start gap-2">
      <label htmlFor={name} className="text-lg font-semibold">
        {Title}
        <span className="text-red-600">*</span> :-
      </label>
      <input
        autoComplete="off"
        type={type}
        id={name}
        name={name}
        className="px-2 py-1 outline-none w-full bg-[#f3f3f3] rounded-md"
        placeholder={placeHolder}
        required
      />
    </div>
  );
};

export default FormInput;
