import React from "react";
import { information } from "../../dummy data/dummy.data";

const InformationComponent = () => {
  return (
    <div className="bg-[#e0e6f3] w-full px-4 py-6 grid grid-cols-2 md:grid-cols-4 items-center shadow-sm my-8 gap-4 md:gap-0">
      {information.map((e, index) => (
        <div
          key={index}
          className="col-spam-1 flex flex-col items-center justify-center"
        >
          <div className={e.color + " rounded-full w-fit h-fit p-2"}>
            <e.icon className="invert h-[2rem] w-[2rem]" />
          </div>
          <span className="font-bold text-xl lg:text-2xl mt-2 lg:leading-4">
            {e.no}
          </span>
          <span className=" capitalize text-xl font-medium lg:text-xl">
            {e.title}
          </span>
        </div>
      ))}
    </div>
  );
};

export default InformationComponent;
