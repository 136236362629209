import React from "react";
import Banner2Component from "../components/Banner/banner2.component";
import { prelims } from "../utils/images";
import DuelCard from "../components/Cards/Duel.card";
import { Paper } from "../dummy data/dummy.data";

const PaperPage = () => {
  return (
    <div className="w-full">
      <Banner2Component classCSS="h-[10rem] md:h-[15rem]" url={prelims} />
      <div className="w-full py-4 px-2">
        <div className="bg-blue-100 px-2 lg:px-8 py-4 rounded-lg flex flex-col items-center">
          {Paper.map((e, index) => (
            <div key={index} className="mb-8 w-full flex flex-col items-center">
              <span className="w-full px-5 md:px-10 text-base md:text-lg capitalize font-bold text-center">
                {e.title ? e.title : "prelims paper 1 (pervious years paper)"}
              </span>
              <div className="w-full flex flex-wrap items-center justify-center mt-4">
                {e.paper.map((e, index) => (
                  <DuelCard
                    key={index}
                    year={e.Year}
                    paper={e.Paper}
                    answer={e.Answer}
                    quePaperLink={e.quePaperLink}
                    ansPaperLink={e.ansPaperLink}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaperPage;
